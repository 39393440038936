import AuthenticationService from "../services/AuthenticationService";
import OrganizationService from "../services/OrganizationService";
import {
  setDisposalReasons,
  setOrganizations,
  setOrganizationProfile,
  setRawWastes,
  setUsers,
  setAvailableRawWastes,
  setInvites,
  setDashboardInfo,
  setVisitSettings,
} from "../slices/orgSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";
import { signOut } from "./user";

export const fetchOrganizations = () => (dispatch) => {
  OrganizationService.fetchOrgsList()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setOrganizations(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const fetchRawWastes = () => (dispatch) => {
  OrganizationService.fetchWastes()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setRawWastes(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};
export const fetchAvailableRawWastes = () => (dispatch) => {
  OrganizationService.fetchAvailableRawWastes()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setAvailableRawWastes(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const editRawWaste = (id, data, setStage) => (dispatch) => {
  OrganizationService.editRawWaste(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchRawWastes());
        setStage(1);
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const addRawWastes = (data, setStage) => (dispatch) => {
  OrganizationService.addRawWastes(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchRawWastes());
        setStage(1);
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const activeRawWaste = (id) => (dispatch) => {
  OrganizationService.activeRawWaste(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchRawWastes());
        showNotification("Updated successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const inactiveRawWaste = (id) => (dispatch) => {
  OrganizationService.inactiveRawWaste(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchRawWastes());
        showNotification("Updated successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchAllUsers = () => (dispatch) => {
  OrganizationService.fetchUsers()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setUsers(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchDisposalReasons = () => (dispatch) => {
  OrganizationService.listDisposalReasons()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(
          setDisposalReasons(
            res.data.data.map((row) => {
              return { ...row, id: row._id };
            })
          )
        );
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const createDisposalReason = (data) => (dispatch) => {
  OrganizationService.createDisposalReason(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Created successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
      showNotification("Created successfully!", "success");
    });
};

export const editDisposalReason = (id, data) => (dispatch) => {
  OrganizationService.editDisposalReason(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Updated successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const archiveDisposalReason = (id) => (dispatch) => {
  OrganizationService.archiveDisposalReason(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Archived successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const unarchiveDisposalReason = (id) => (dispatch) => {
  OrganizationService.unarchiveDisposalReason(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Archived successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const deactivateDisposalReason = (id) => (dispatch) => {
  OrganizationService.deactivateDisposalReason(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Deactivated successfully!", "success");
      }
    })
    .catch((err) => {
      if (!err.response?.data) showNotification("Fetch Failed", "error");
      else
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
    });
};

export const activateDisposalReason = (id) => (dispatch) => {
  OrganizationService.activateDisposalReason(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Activated successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const removeDisposalReason = (id) => (dispatch) => {
  OrganizationService.removeDisposalReason(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDisposalReasons());
        showNotification("Removed successfully!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || err.response.data.message
          : "Error occured",
        "error"
      );
    });
};

export const fetchOrganizationInfo =
  (callback = () => {}) =>
  (dispatch) => {
    OrganizationService.fetchOrganizationInfo()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setOrganizationProfile(res.data.data));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
          else if (err.response.data.message === "no_org_selected") {
            callback();
          }
        }
      });
  };

export const updateOrganizationInfo =
  (data, fallback = () => {}) =>
  (dispatch) => {
    OrganizationService.updateOrganizationInfo(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Updated successfully!", "success");
          dispatch(fetchOrganizationInfo(res.data.data));
          fallback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response?.data?.message
            ? ERROR[err.response.data.message] || err.response.data.message
            : "Error occured",
          "error"
        );
      });
  };

export const selectOrganization =
  (data, callBack = () => {}, fallBack = () => {}) =>
  (dispatch) => {
    OrganizationService.selectOrganization(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          // showNotification("Organization selected", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        fallBack();
      });
  };

export const fetchAllInvites = () => (dispatch) => {
  OrganizationService.fetchInvitation()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setInvites(res.data.data));
      }
    })
    .catch((err) => {});
};
export const fetchDashboardInfo = (data) => (dispatch) => {
  OrganizationService.fetchDashboardInfo(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setDashboardInfo(res.data.data));
      }
    })
    .catch((err) => {});
};

export const accpetInvite = (org_id) => (dispatch) => {
  AuthenticationService.acceptInvite(org_id)
    .then((res) => {
      showNotification("You accepted the invitation", "success");
      dispatch(fetchAllInvites());
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const rejectInvite = (org_id) => (dispatch) => {
  AuthenticationService.rejectInvite(org_id)
    .then((res) => {
      showNotification("You rejected the invitation", "success");
      dispatch(fetchAllInvites());
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const updateVisitSetting =
  (data, fallback = () => {}) =>
  (dispatch) => {
    AuthenticationService.updateVisitSetting(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Updated Visit Settings", "success");
          dispatch(getVisitSetting(fallback));
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const getVisitSetting =
  (fallback = () => {}) =>
  (dispatch) => {
    AuthenticationService.getVisitSetting()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setVisitSettings(res.data.data));
          fallback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const blockOrganization = () => (dispatch) => {
  AuthenticationService.blockOrganization()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchOrganizationInfo());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const unblockOrganization = () => (dispatch) => {
  AuthenticationService.unblockOrganization()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchOrganizationInfo());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const removeOrg =
  (callBack = () => {}) =>
  (dispatch) => {
    OrganizationService.removeOrg()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Organization has been removed", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const chargeCredits = async (amount) => {
  return OrganizationService.chargeCredits(amount)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        return res.data.url;
      }
    })
    .catch(() => {
      showNotification("Failed to charge credits", "error");
    });
};
