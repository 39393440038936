import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import DateDialog from "./DateDialog";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import { PageTitle } from "../../../components/Typography";
import {
  fetchVisitsList,
  rescheduleVisit,
  updateVisitStatus,
} from "../../../actions/visit";
import {
  PURPOSE_TYPES,
  getDateStringFromNum,
  getServerTimestamp,
} from "../../../utils/helpers";
import { DRIVER } from "../../../utils/constants";
import { classes } from "../../../utils/class";
import { setCurrentScheduled } from "../../../slices/visitsSlice";

const ScheduledVisitsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state) => state.user.role);
  const orgData = useSelector((state) => state.organizations.profile);
  const visitsData = useSelector((state) => state.visits.scheduled_visits);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [dateDialog, setDateDialog] = useState(false);
  const [dateAnchor, setDateAnchor] = useState(null);
  const [current, setCurrent] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    dispatch(
      fetchVisitsList(true, () => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleAccept = async () => {
    dispatch(setCurrentScheduled(selectedRow));
    dispatch(updateVisitStatus(selectedRow?._id, { status: "planned" }));
    setOpenConfirmModal(false);
  };

  const onClickDate = (date) => {
    dispatch(
      rescheduleVisit(current._id, {
        date: getServerTimestamp(
          new Date(`${date.$y}-${date.$M + 1}-${date.$D}`).getTime(),
          orgData.timezone
        ),
      })
    );
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        return <StateSpan status={props.row.status} />;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {`${getDateStringFromNum(props.row.date, orgData.timezone)}`}
        </span>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination Warehouse",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {props.row.destination_warehouse_name || ""}
        </span>
      ),
    },
    {
      field: "transfer",
      headerName: "Expected Transfer",
      width: 400,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "100px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "zone",
      headerName: "Zone",
      width: 180,
      renderCell: (props) => {
        let zones = [];
        if (props.row.zones.length > 0) {
          props.row.zones.map((item, index) => {
            return zones.push(item?.name);
          });
        }
        let zoneData = zones.join(", ");
        return <span className="mui-ellipsis">{zoneData}</span>;
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      width: 150,
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {props.row.vehicles[0]?.reg_plate || ""}
          </span>
        );
      },
    },
    {
      field: "driver_name",
      headerName: "Driver",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.row.driver_name || ""}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (props) => {
        const status = props.row.status;
        const handle = (e, action) => {
          dispatch(setCurrentScheduled(props.row));
          e.stopPropagation();
          if (action === "go") {
            navigate("/add-visit-log");
          } else if (action !== "schedule")
            dispatch(updateVisitStatus(props.row._id, { status: action }));
          else {
            setCurrent(props.row);
            setDateDialog(true);
            setDateAnchor(e.currentTarget);
          }
        };

        const handleOpenConfirmModal = (e) => {
          e.stopPropagation();
          setOpenConfirmModal(true);
          setSelectedRow(props?.row);
        };

        return (
          <Stack direction="row">
            <IconButton
              disabled={status !== "suggestion" || role === DRIVER}
              onClick={(e) => handleOpenConfirmModal(e)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              disabled={
                (status !== "planned" && status !== "suggestion") ||
                role === DRIVER
              }
              onClick={(e) => handle(e, "discard")}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              disabled={
                (status !== "overdue" &&
                  status !== "planned" &&
                  status !== "suggestion") ||
                role === DRIVER
              }
              onClick={(e) => handle(e, "schedule")}
            >
              <DateRangeIcon />
            </IconButton>
            <IconButton
              disabled={status !== "planned"}
              onClick={(e) => handle(e, "go")}
            >
              <DirectionsOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      renderCell: (props) => {
        return (
          <>
            <div
              style={{ width: "60px", display: "flex", alignItems: "center" }}
            >
              {props.row.notes && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.notes}
                  placement="top"
                >
                  <SpeakerNotesOutlinedIcon />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº Visits"
                content={visitsData.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
          </Box>
        </Box>
        <Box sx={{ width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              label="Search visit"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "pickup_point_name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton variant="rounded" width={"100%"} />
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ maxWidth: `${window.innerWidth - 400}px` }}>
          {isLoaded ? (
            <DataTable
              fit="100%"
              breakpoint="xl"
              rows={visitsData}
              columns={columns}
              filterModel={filterModel}
              onRowClick={(params, events, details) => {
                dispatch(setCurrentScheduled(params.row));
                navigate("/edit-scheduled-visit");
              }}
              initialState={{
                pinnedColumns: { right: ["actions"] },
              }}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Box>
        <DateDialog
          open={dateDialog}
          handleClose={() => {
            setDateDialog(false);
          }}
          anchorEl={dateAnchor}
          onClickDate={onClickDate}
        />
      </Box>
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Stack sx={{ ...classes.popup, width: 470 }}>
          <Stack>
            <PageTitle>Accept Suggestion</PageTitle>
          </Stack>
          <IconButton
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
            onClick={handleCloseConfirmModal}
          >
            <CloseIcon />
          </IconButton>

          <Typography fontSize={16} fontWeight={500}>
            Accepting this suggested visit will hold the below number of
            credits.
          </Typography>

          <Stack p={3} borderRadius="6px" bgcolor="#464A53">
            <Typography fontSize={20} fontWeight={700} color="#FFF">
              Held Credit Calculation
            </Typography>
            <Typography fontSize={16} fontWeight={500} color="#FFF" pt={1.5}>
              {orgData?.credit_per_kg || 0} credits per kg
            </Typography>
            <Stack
              pt={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize={16} fontWeight={700} color="#FFF">
                Total Credits Required:
              </Typography>
              <Typography fontSize={20} fontWeight={700} color="#FFF">
                {(
                  orgData?.credit_per_kg * selectedRow?.quantity
                )?.toLocaleString("en-US") || 0}{" "}
                credits
              </Typography>
            </Stack>
            {(orgData?.available_balance < 50 ||
              orgData?.available_balance <
                orgData?.credit_per_kg * selectedRow?.quantity) && (
              <Stack mt={4} py={3} px={2} bgcolor="#EEB7BB">
                <Typography fontSize={12} fontWeight={700} color="#282828">
                  YOUR CURRENT BALANCE
                </Typography>
                <Typography
                  pt={1}
                  fontSize={20}
                  fontWeight={700}
                  color="#23222D"
                >
                  {orgData?.available_balance?.toLocaleString("en-US") || 0}{" "}
                  Credits
                </Typography>
                <Stack
                  mt={3}
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    borderLeft: "4px solid #D64C56",
                    background: "#F8F8F8",
                    padding: "16px 24px",
                  }}
                >
                  <WarningAmberIcon
                    style={{
                      width: 16,
                      fill: "#D64C56",
                    }}
                  />
                  <Typography color="#D64C56" fontSize={16} fontWeight={500}>
                    Low Balance: <b>Recharge Now</b>
                  </Typography>
                </Stack>
                <Stack pt={3}>
                  <Button
                    sx={classes.buttonPrimary}
                    onClick={() => navigate("/recharge-balance")}
                  >
                    Recharge Now
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>

          <Stack gap={2} direction="row" justifyContent="space-between">
            <Button sx={classes.buttonCancel} onClick={handleCloseConfirmModal}>
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={handleAccept}>
              Accept
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default ScheduledVisitsView;
