import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from "react";
import { useQuery } from "@tanstack/react-query";
import $ from "jquery";

import { convertDate } from "../../../../utils/date";
import { classes } from "../../../../utils/class";
import ZoneSerivce from "../../../../services/ZoneService";
import FleetService from "../../../../services/FleetService";

const ListItem = ({
  children,
  isSelected = false,

  backgroundColor = null,
  onClick = () => {},
}) => {
  const style = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      position: "relative",
      alignItems: "center",
      pr: "5px",
      display: "flex",
      cursor: "pointer",
      "&.isHovered": {
        backgroundColor: "#EBF15540",
      },
    };
  }, [backgroundColor]);

  const style2 = useMemo(() => {
    return {
      paddingX: "5px",
      borderRadius: "5px",
      color: isSelected ? "black" : "white",
      display: "flex",
      alignItems: "center",
      backgroundColor: isSelected ? "#EBF155" : "none",
      width: "100%",
      height: "100%",
      fontSize: "11px",
      fontWeight: 600,
      lineHeight: "14px",
    };
  }, [isSelected]);

  return (
    <Box height={"100%"} onClick={onClick} sx={style} className="hovered">
      <Box sx={style2}>{children}</Box>
    </Box>
  );
};

const HeaderItem = ({
  first = false,
  children,
  isSelected = false,
  backgroundColor = null,
  onClickHandler = () => {},
}) => {
  const style1 = useMemo(() => {
    return {
      width: "28px",
      height: "28px",
      padding: "1px",
      display: "flex",
      textAlign: "center",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "10px",
      whiteSpace: "break-spaces",
      flexDirection: "column",
      cursor: "pointer",
      borderTopLeftRadius: "2px",
      borderTopRightRadius: "2px",
      textTransform: "uppercase",
      backgroundColor,
      "&.isHovered": {
        backgroundColor: "#EBF15540",
      },
    };
  }, [backgroundColor]);

  const style2 = useMemo(() => {
    return {
      width: "100%",
      height: "100%",
      backgroundColor: isSelected ? "#EBF155" : first ? "#EBF15540" : "none",
      color: isSelected ? "black" : first ? "#EBF155" : "white",
      borderRadius: "2px",
      alignItems: "center",
      justifyContent: "center",
    };
  }, [isSelected, first]);

  return (
    <Box
      sx={style1}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      onClick={onClickHandler}
      className="hovered"
    >
      <Stack sx={style2}>{children}</Stack>
    </Box>
  );
};

const Item = ({ children, color, selected = false, opacityMultiplier = 1 }) => {
  const style1 = useMemo(() => {
    return {
      height: "100%",
      width: "100%",
      padding: "1px",
      cursor: "pointer",
      backgroundColor: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "visible",
      "&.isHovered": {
        backgroundColor: "#EBF15540",
      },
    };
  }, []);

  const style2 = useMemo(() => {
    const decimalValue = Math.floor(
      Math.min(children * opacityMultiplier * 256, 255)
    );

    const hexString = decimalValue.toString(16);
    return {
      backgroundColor: color + hexString,
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
    };
  }, [color, children, opacityMultiplier]);

  const style3 = useMemo(() => {
    return {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderStyle: "solid",
      borderRadius: "4px",
      borderWidth: selected ? "2px" : "0px",
      borderColor: "#EBF155",
      zIndex: 1,
    };
  }, [selected]);

  return (
    <Box sx={style1} className="hovered" position={"relative"}>
      <Box sx={style2}>{children}</Box>
      <Box sx={style3}></Box>
    </Box>
  );
};

const MemoizedItem = memo(({ color, selected, opacityMultiplier, count }) => (
  <Item color={color} selected={selected} opacityMultiplier={opacityMultiplier}>
    {count}
  </Item>
));

const TableCell = memo(
  ({
    index,
    index2,
    date,
    d,
    title,
    color,
    isFirstLoad,
    selectedRow,
    selectedCol,
    opacityMultiplier,
    onItemClick,
    setIsFirstLoad,
    hoverTable,
    handleOpenContextMenu,
    handleOpenContextMenuVehicle,
  }) => {
    const isSelected =
      isFirstLoad && title === "Assigned Collections"
        ? false
        : index === selectedRow && index2 === selectedCol;

    const handleClick = () => {
      onItemClick(index, index2);
      setIsFirstLoad(false);
    };

    const handleMouseOver = () => {
      hoverTable(index, index2 + 1);
    };

    const handleContextMenu = (ev) => {
      if (title === "Unassigned Collections") {
        handleOpenContextMenu(ev, d, date);
      } else if (title === "Assigned Collections") {
        handleOpenContextMenuVehicle(ev, d, date);
      }
    };

    return (
      <td
        key={index2}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onContextMenu={handleContextMenu}
      >
        <MemoizedItem
          color={color}
          selected={isSelected}
          opacityMultiplier={opacityMultiplier}
          count={d?.data[date]?.collectionCount || 0}
        />
      </td>
    );
  }
);

const Collection = ({
  title = "Unassigned Collections",
  listTitle = "Zone Name",
  backgroundColor = "#47455E",
  color = "#F68D8D",
  data = [],
  dateHeader = [],
  collectionTitle = "",
  id = "collection",
  addCollection = () => {},
  onRowChange = () => {},
  onColChange = () => {},
  onClick = () => {},
}) => {
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedCol, setSelectedCol] = useState(0);
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [opacityMultiplier, setOpacityMultiplier] = useState(1);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const [anchor, setAnchor] = useState(null);
  const [anchorVehicle, setAnchorVehicle] = useState(null);
  const [context, setContext] = useState({
    row: null,
    col: null,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleDragStart = useCallback(
    (e) => {
      if (!ourRef.current) return;
      const slider = ourRef.current;
      const startX = e.pageX - slider.offsetLeft;
      const startY = e.pageY - slider.offsetTop;
      const scrollLeft = slider.scrollLeft;
      const scrollTop = slider.scrollTop;
      mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
      setIsMouseDown(true);
      document.body.style.cursor = "grabbing";
    },
    [ourRef]
  );

  const handleDragEnd = useCallback(() => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  }, [ourRef]);

  const handleDrag = useCallback(
    (e) => {
      if (!isMouseDown || !ourRef.current) return;
      e.preventDefault();
      const slider = ourRef.current;
      const x = e.pageX - slider.offsetLeft;
      const y = e.pageY - slider.offsetTop;
      const walkX = (x - mouseCoords.current.startX) * 1.5;
      const walkY = (y - mouseCoords.current.startY) * 1.5;
      if (Math.abs(walkX) > Math.abs(walkY))
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    },
    [isMouseDown]
  );

  const onItemClick = useCallback(
    (index, index2) => {
      setSelectedCol(index2);
      setSelectedRow(index);
      onRowChange(index);
      onColChange(index2);
    },
    [onColChange, onRowChange]
  );

  const handleOpenContextMenu = (event, row, col) => {
    setAnchor(event.currentTarget);
    setContext({
      row: row,
      col: col,
    });
    event.preventDefault();
  };

  const handleOpenContextMenuVehicle = (event, row, col) => {
    setAnchorVehicle(event.currentTarget);
    setContext({
      row: row,
      col: col,
    });
    event.preventDefault();
  };

  const handleCloseContextMenu = () => {
    setAnchor(null);
  };

  const handleCloseContextMenuVehicle = () => {
    setAnchorVehicle(null);
  };

  useEffect(() => {
    let max = 0;
    const flattenedData = data.flatMap((d) =>
      dateHeader.map((date) => d.data[date]?.collectionCount || 0)
    );
    max = Math.max(...flattenedData);
    if (max !== 0) setOpacityMultiplier(1 / max);
  }, [data, dateHeader]);

  const style1 = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
      fontSize: "11px",
      fontWeight: 600,
    };
  }, [backgroundColor]);

  const style2 = useMemo(() => {
    return {
      color: "white",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "24px",
      letterSpacing: "-0.2px",
    };
  }, []);

  const style3 = useMemo(() => {
    return {
      borderBottom: "2px solid rgba(54, 53, 70, 1)",
      marginBottom: "1px",
      backgroundColor: backgroundColor,
    };
  }, [backgroundColor]);

  const hoverTable = (row, col) => {
    const tds = $(`#${id} td`).get();
    const ths = $(`#${id} th`).get();

    for (let th of ths) {
      let index = $(th).index();
      if (index === col) {
        $(th).find(".hovered").addClass("isHovered");
      } else {
        $(th).find(".hovered").removeClass("isHovered");
      }
    }

    for (let td of tds) {
      let colIndex = $(td).index();
      let rowIndex = $(td).parent().index();
      if (
        (col !== 0 && (rowIndex === row || colIndex === col)) ||
        (col === 0 && rowIndex === row)
      ) {
        $(td).find(".hovered").addClass("isHovered");
      } else {
        $(td).find(".hovered").removeClass("isHovered");
      }
    }
  };

  return (
    <>
      <Stack sx={style1} width={"fit-content"} alignItems={"flex-start"}>
        <Stack
          px="16px"
          py="24px"
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography sx={style2}>{title}</Typography>
          <Button sx={classes.buttonSave} onClick={onClick}>
            {collectionTitle}
          </Button>
        </Stack>

        <table ref={ourRef} className="collectionTable" style={style1} id={id}>
          <thead>
            <tr
              onMouseDown={handleDragStart}
              onMouseUp={handleDragEnd}
              onMouseMove={handleDrag}
              onMouseLeave={handleDragEnd}
              style={style3}
            >
              <th style={style1}>{listTitle}</th>
              {dateHeader.map((date, index) => {
                return (
                  <th
                    key={index}
                    onMouseOver={() => {
                      hoverTable(0, index + 1);
                    }}
                  >
                    <HeaderItem
                      isSelected={
                        isFirstLoad && title === "Assigned Collections"
                          ? false
                          : index === selectedCol
                      }
                      backgroundColor={backgroundColor}
                      onClickHandler={() => {
                        setSelectedCol(index);
                        onColChange(index);
                        setIsFirstLoad(false);
                      }}
                    >
                      <Box>{convertDate(date).split(" ")[0]}</Box>
                      <Box>{convertDate(date).split(" ")[1]}</Box>
                    </HeaderItem>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => {
              return (
                <tr key={index}>
                  <Tooltip
                    title={
                      d?.plate === undefined
                        ? d.name
                        : d.name + " (" + d.plate + ")"
                    }
                  >
                    <td
                      style={{
                        backgroundColor: backgroundColor,
                      }}
                      onMouseOver={() => {
                        hoverTable(index, 0);
                      }}
                    >
                      <ListItem
                        isSelected={
                          isFirstLoad && title === "Assigned Collections"
                            ? false
                            : index === selectedRow
                        }
                        backgroundColor={backgroundColor}
                        onClick={() => {
                          setSelectedRow(index);
                          onRowChange(index);
                          setIsFirstLoad(false);
                        }}
                      >
                        {d?.plate === undefined
                          ? truncateText(d.name, 25)
                          : truncateText(d.name + " (" + d.plate + ")", 25)}
                      </ListItem>
                    </td>
                  </Tooltip>
                  {dateHeader.map((date, index2) => {
                    return (
                      <TableCell
                        key={index2}
                        index={index}
                        index2={index2}
                        date={date}
                        d={d}
                        title={title}
                        color={color}
                        isFirstLoad={isFirstLoad}
                        selectedRow={selectedRow}
                        selectedCol={selectedCol}
                        opacityMultiplier={opacityMultiplier}
                        onItemClick={onItemClick}
                        setIsFirstLoad={setIsFirstLoad}
                        hoverTable={hoverTable}
                        handleOpenContextMenu={handleOpenContextMenu}
                        handleOpenContextMenuVehicle={
                          handleOpenContextMenuVehicle
                        }
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Stack>
      <DevContextMenu
        anchor={anchor}
        row={context.row}
        col={context.col}
        onClose={handleCloseContextMenu}
      />
      <DevContextMenuVehicle
        anchor={anchorVehicle}
        row={context.row}
        col={context.col}
        onClose={handleCloseContextMenuVehicle}
      />
    </>
  );
};

const DevContextMenu = ({ anchor, row, col, onClose }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["zoneListPickupRoutes", row?.zone?._id, col],
    queryFn: async () => {
      const result = await ZoneSerivce.listPickupRoutes(
        row?.zone?._id,
        new Date(col).getTime()
      );
      return result.data.data;
    },
  });

  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      disableScrollLock
      onClose={onClose}
    >
      {isLoading || !data ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          px={8}
          py={12}
        >
          <CircularProgress />
          <Typography mt={2}>Loading</Typography>
        </Stack>
      ) : data.length === 0 ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          px={8}
          py={12}
        >
          <Typography mt={2}>No data</Typography>
        </Stack>
      ) : (
        <List>
          {data.map((row, index) => (
            <ListItem key={`context-item-${index}`}>
              <ListItemText>
                <Typography color="text.primary">
                  {row.pickup_point_name}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </Popover>
  );
};

const DevContextMenuVehicle = ({ anchor, row, col, onClose }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["vehicleListPickupRoutes", row?.vehicle?._id, col],
    queryFn: async () => {
      const result = await FleetService.fetchVehicleRoutePickup(
        row?.vehicle?._id,
        new Date(col).getTime()
      );
      return result.data.data;
    },
  });

  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      disableScrollLock
      onClose={onClose}
    >
      {isLoading || !data ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          px={8}
          py={12}
        >
          <CircularProgress />
          <Typography mt={2}>Loading</Typography>
        </Stack>
      ) : data.length === 0 ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          px={8}
          py={12}
        >
          <Typography mt={2}>No data</Typography>
        </Stack>
      ) : (
        <List>
          {data.map((row, index) => {
            return (
              <ListItem key={`context-item-${index}`}>
                <ListItemText>
                  <Typography color="text.primary">
                    {row.pickup_point_name}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      )}
    </Popover>
  );
};

const MemorizedCollection = React.memo(Collection);
export default MemorizedCollection;
