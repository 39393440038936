import { ThemeProvider } from "@mui/material/styles";
import theme from "./themes";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import MainLayout from "./layout/MainLayout";
// authentication
import SignupPage from "./views/pages/authentication/SignupPage";
import SigninPage from "./views/pages/authentication/SigninPage";
// import ChooseAccountPage from "./views/pages/authentication/ChooseAccountPage";
import CreateOrganizationPage from "./views/pages/authentication/CreateOrganizationPage";
import ForgotPassword from "./views/pages/authentication/ForgotPasswordPage";
import SelectOrganizationForSigninPage from "./views/pages/authentication/SelectOrganizationForSigninPage";
// import SelectOrganizationPage from "./views/pages/authentication/SelectOrganizationPage";
import SetNewPasswordPage from "./views/pages/authentication/ResetPassword/SetNewPasswordPage";
import EmailVerificationPage from "./views/pages/authentication/Verificatioin/EmailVerificationPage";
// warehouse
import WarehouseListPage from "./views/pages/warehouse/WarehouseListPage";
import AddWarehousePage from "./views/pages/warehouse/AddWarehousePage";
import WarehouseProfilePage from "./views/pages/warehouse/WarehouseProfilePage";
// legal
import TermsAndConditions from "./views/pages/legal/TermsAndConditionsPage";
// snackbar
import { SnackbarProvider } from "notistack";
// fleet
import AddVehiclePage from "./views/pages/fleet/AddVehiclePage";
import VehicleListPage from "./views/pages/fleet/VehicleListPage";
import VehicleDetailPage from "./views/pages/fleet/VehicleDetailPage";
// pickup
import AddPickupPage from "./views/pages/pickup/AddPickupPage";
import PickupListPage from "./views/pages/pickup/PickupListPage";
import PickupDetailPage from "./views/pages/pickup/PickupDetailPage";
//producers
import ProducerListPage from "./views/pages/producers/ProducersListPage";
// inventory
import AddInventory from "./views/pages/inventory/AddInventoryPage";
import Inventory from "./views/pages/inventory/InventoryListPage";
// tickets
import AddTicketsPage from "./views/pages/tickets/AddTicketsPage";
// redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import { SETTINGS_NAVIGATION } from "./utils/constants";
import TicketsListPage from "./views/pages/tickets/TicketsListPage";
import InventorySubList from "./views/pages/inventory/InventorySubListPage";
import PriceHistoryPage from "./views/pages/inventory/PriceHistoryPage";
import MainSettingsPage from "./views/pages/settings/MainSettingsPage";
import AddProducerPage from "./views/pages/producers/AddProducerPage";
import ProducerDetailPage from "./views/pages/producers/ProducerDetailPage";
import VisitsListPage from "./views/pages/visits/VisitsListPage";
import VisitsDetailPage from "./views/pages/visits/VisitsDetailPage";
import TransferListPage from "./views/pages/transfer/TransferListPage";
import TransferDetailPage from "./views/pages/transfer/TransferDetailPage";
import DistributeResumePage from "./views/pages/transfer/DistributeResumePage";
import ZonesListPage from "./views/pages/zone/ZonesListPage";
import AddZonePage from "./views/pages/zone/AddZonePage";
import ZoneDetailPage from "./views/pages/zone/ZoneDetailPage";
import ManagePickupPoint from "./views/pages/zone/ManagePickupPoint";
import CertificatesListPage from "./views/pages/certificates/CertificatesListPage";
import MemberListPage from "./views/pages/member/MemberListPage";
import AddMember from "./views/pages/member/AddMemberPage";
import ReinvitationPage from "./views/pages/member/ReinvitationPage";
import DaysOff from "./views/pages/member/DaysOff";
import AddVisitPage from "./views/pages/visits/AddVisitPage";
import EditScheduledVisitPage from "./views/pages/visits/EditScheduledVisitPage";
import AddTransferPage from "./views/pages/transfer/AddTransferPage";
import AuthProvider from "./AuthProvider";
import Dashboard from "./views/pages/dashboard/DashboardPage";
import CreateOrganizationDetailPage from "./views/pages/authentication/CreateOrganizationDetailPage";
import CreateCompanyIdPage from "./views/pages/authentication/CreateCompanyIdPage";
import Reviewing from "./views/pages/authentication/Reviewing";
import ReviewingProducer from "./views/pages/authentication/ReviewingProducer";
import Error404 from "./views/pages/Error404/Error404";
import PickupRoutes from "./views/pages/routes";
import SetupRawWaste from "./views/pages/authentication/accountConfiguration/SetupRawWaste";
import SetupRawWasteInProducer from "./views/pages/authentication/accountConfiguration/SetupRawWasteInProducer";
import SetupCollectionPointsInProducer from "./views/pages/authentication/accountConfiguration/SetupCollectionPointsInProducer";
import ScrollPlayground from "./views/pages/Test/Table";
import SetupWarehouse from "./views/pages/authentication/accountConfiguration/SetupWarehouse";
import SetupFleet from "./views/pages/authentication/accountConfiguration/SetupFleet";
import SetupProducer from "./views/pages/authentication/accountConfiguration/SetupProducer";
import AcceptInvitation from "./views/pages/authentication/AcceptInvitation/AcceptVerificationPage";
import WTNPage from "./views/pages/transfer/WTNPage";
import Balance from "./views/pages/balance";
import RechargeBalancePage from "./views/pages/balance/RechargeBalancePage";
import "./index.css";

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider autoHideDuration={5000}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Routes>
                  <Route path="accept-invite" element={<AcceptInvitation />} />
                  {/*setup account*/}
                  <Route path="define">
                    <Route
                      path="waste"
                      element={
                        <AuthProvider isWizard>
                          <SetupRawWaste />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="waste-in-producer"
                      element={
                        <AuthProvider isWizard>
                          <SetupRawWasteInProducer />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="warehouse"
                      element={
                        <AuthProvider isWizard>
                          <SetupWarehouse />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="fleet"
                      element={
                        <AuthProvider isWizard>
                          <SetupFleet />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="producer"
                      element={
                        <AuthProvider isWizard>
                          <SetupProducer />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="collection-points"
                      element={
                        <AuthProvider isWizard>
                          <SetupCollectionPointsInProducer />
                        </AuthProvider>
                      }
                    />
                  </Route>
                  {/* authentication */}
                  <Route path="/auth">
                    <Route path="signup" element={<SignupPage />} />

                    <Route path="signin" element={<SigninPage />} />
                    <Route
                      path="verification"
                      element={<EmailVerificationPage />}
                    />
                    <Route
                      path="forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="reset-password"
                      element={<SetNewPasswordPage />}
                    />
                    <Route
                      path="create-organization"
                      element={
                        <AuthProvider noCheckOrgInfo>
                          <CreateOrganizationPage />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="create-organization-detail"
                      element={
                        <AuthProvider noCheckOrgInfo>
                          <CreateOrganizationDetailPage />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="create-company-id"
                      element={
                        <AuthProvider noCheckOrgInfo>
                          <CreateCompanyIdPage />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="reviewing"
                      element={
                        <AuthProvider isWizard>
                          <Reviewing />
                        </AuthProvider>
                      }
                    />
                    <Route
                      path="reviewing-producer"
                      element={
                        <AuthProvider onCheckOrgInfo>
                          <ReviewingProducer />
                        </AuthProvider>
                      }
                    />

                    <Route
                      path="signin-select-org"
                      element={
                        <AuthProvider noCheckOrgInfo>
                          <SelectOrganizationForSigninPage />
                        </AuthProvider>
                      }
                    />
                  </Route>

                  {/* legal */}
                  <Route path="/legal">
                    <Route
                      path="terms-conditions"
                      element={<TermsAndConditions />}
                    />
                  </Route>

                  <Route
                    path="/"
                    element={
                      <AuthProvider>
                        <MainLayout />
                      </AuthProvider>
                    }
                  >
                    <Route index element={<Error404 />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="balance" element={<Balance />} />
                    <Route
                      path="recharge-balance"
                      element={<RechargeBalancePage />}
                    />
                    {/* warehouse */}
                    <Route path="warehouse" element={<WarehouseListPage />} />
                    <Route
                      path="add-warehouse"
                      element={<AddWarehousePage />}
                    />
                    <Route
                      path="warehouse-profile"
                      element={<WarehouseProfilePage />}
                    />
                    {/* vehicle */}
                    <Route path="add-vehicle" element={<AddVehiclePage />} />
                    <Route path="vehicle" element={<VehicleListPage />} />
                    <Route
                      path="vehicle-detail"
                      element={<VehicleDetailPage />}
                    />
                    {/* inventory */}
                    <Route path="add-inventory" element={<AddInventory />} />
                    <Route path="inventory" element={<Inventory />} />
                    <Route
                      path="inventory-sublist/:id"
                      element={<InventorySubList />}
                    />
                    <Route
                      path="inventory-price-history/:id"
                      element={<PriceHistoryPage />}
                    />
                    {/*pickup*/}
                    <Route path="add-collection" element={<AddPickupPage />} />
                    <Route path="collection" element={<PickupListPage />} />
                    <Route
                      path="collection-detail"
                      element={<PickupDetailPage />}
                    />

                    {/* producers */}
                    <Route path="producers" element={<ProducerListPage />} />
                    <Route path="add-producer" element={<AddProducerPage />} />
                    <Route
                      path="producers-detail"
                      element={<ProducerDetailPage />}
                    />
                    {/* Tickets */}
                    <Route path="add-tickets" element={<AddTicketsPage />} />
                    <Route path="tickets" element={<TicketsListPage />} />
                    {/* Settings */}
                    <Route path="settings" element={<MainSettingsPage />}>
                      {SETTINGS_NAVIGATION.map((nav, index) => (
                        <Route
                          key={index}
                          path={nav.value}
                          element={nav.component}
                        />
                      ))}
                    </Route>
                    {/* Visits */}
                    <Route path="visits" element={<VisitsListPage />} />
                    <Route
                      path="visits-detail"
                      element={<VisitsDetailPage />}
                    />
                    <Route path="add-visit-log" element={<AddVisitPage />} />
                    <Route
                      path="edit-scheduled-visit"
                      element={<EditScheduledVisitPage />}
                    />
                    {/* Transfer */}
                    <Route path="transfer" element={<TransferListPage />} />
                    <Route
                      path="add-transfer-log"
                      element={<AddTransferPage />}
                    />
                    <Route path="wtn-detail/:id" element={<WTNPage />} />
                    <Route
                      path="transfer-detail/:id/:type"
                      element={<TransferDetailPage />}
                    />
                    <Route
                      path="transfer-distribute-resume"
                      element={<DistributeResumePage />}
                    />
                    {/*zones*/}
                    <Route path="zones" element={<ZonesListPage />} />
                    <Route path="add-zone" element={<AddZonePage />} />
                    <Route path="zones-detail" element={<ZoneDetailPage />} />
                    <Route
                      path="manage-collection/:id"
                      element={<ManagePickupPoint />}
                    />
                    {/* member management */}
                    <Route path="members" element={<MemberListPage />} />
                    <Route path="add-member" element={<AddMember />} />
                    <Route path="daysoff/:id" element={<DaysOff />} />
                    <Route
                      path="reinvitation/:id"
                      element={<ReinvitationPage />}
                    />
                    {/* Certificates */}
                    <Route
                      path="certificates"
                      element={<CertificatesListPage />}
                    />
                    <Route path="*" element={<Error404 />} />
                    {/*Routes */}
                    <Route path="routes" element={<PickupRoutes />} />

                    {/* test */}
                    <Route path="test/table" element={<ScrollPlayground />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
