export const classes = {
  infoText: {
    color: "warnning.secondary",
  },
  cardPrimary: {
    backgroundColor: "card.primary1",
    display: "flex",
    gap: "12px",
    color: "text.contrast",
    flexDirection: "column",
    minWidth: "162px",
    borderRadius: "15px",
    padding: "13px 20px",
  },
  buttonBack: {
    height: "40px",
    fontFamily: "Manrope",
    fontSize: "12px",
    fontWeight: 500,
    color: "text.brown",
    borderColor: "text.primary",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonMoreOption: {
    height: "40px",
    border: "1px solid",
    borderColor: "text.primary",
    color: "text.primary",
    fontFamily: "Manrope",
    fontSize: "16px",
    width: "fit-content",
    lineHeight: "14px",
    borderRadius: "8px",
    "&:hover": {
      color: "text.contrast",
      backgroundColor: "background.brown",
    },
    px: "10px",
    py: "13px",
  },
  buttonPrimary: {
    height: "40px",
    backgroundColor: "text.primary",
    color: "#C0D4E4",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "13px 10px",
    borderRadius: "8px",
    "&:hover": {
      color: "#C0D4E4",
      backgroundColor: "#464A53",
    },
    "&:focus": {
      backgroundColor: "text.primary",
      outline: "2px solid #EBF15580",
    },
    "&:active": {
      backgroundColor: "#131313",
    },
    "&:disabled": {
      color: "text.accent",
      backgroundColor: "#464A53",
    },
  },

  buttonSave: {
    height: "40px",
    backgroundColor: "text.primary",
    color: "#C0D4E4 !important",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "13px 10px",
    borderRadius: "8px",
    "&:hover": {
      color: "#C0D4E4",
      backgroundColor: "#464A53",
    },
    "&:active": {
      backgroundColor: "#131313",
    },
    "&:focus": {
      backgroundColor: "text.primary",
      outline: "2px solid #EBF15580",
    },
  },
  buttonYellow: {
    height: "40px",
    color: "text.primary",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "13px 10px",
    borderRadius: "8px",
    border: "none",
    borderColor: "text.primary",
    backgroundColor: "#EBF155",
    "&:hover": {
      color: "#464A53",
      border: "none",
      backgroundColor: "#EBF155",
    },
    "&:active": {
      color: "text.primary",
      border: "none",
    },
    "&:focus": {
      color: "text.primary",
      border: "none",
      outline: "2px solid #EBF15580",
    },
  },
  buttonCancel: {
    height: "40px",
    color: "text.primary",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "13px 10px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "text.primary",
    "&:hover": {
      color: "#464A53",
      border: "1px solid #464A53",
      backgroundColor: "white",
    },
    "&:active": {
      color: "text.primary",
      border: "1px solid text.primary",
    },
    "&:focus": {
      color: "text.primary",
      border: "1px solid text.primary",
      outline: "2px solid #EBF15580",
    },
  },
  orgCreateButton: {
    height: "40px",
    color: "#C0D4E4",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "14px 10px",
    borderRadius: "8px",
    borderWidth: "0px",
    fontWeight: "500",
    backgroundColor: "text.primary",
    "&:hover": {
      borderWidth: "0px",
      backgroundColor: "#EBF155",
      color: "text.primary",
    },
    "&:active": {
      borderWidth: "0px",
      color: "text.primary",
      backgroundColor: "#E8E70D",
    },
    "&:focus": {
      borderWidth: "0px",
      backgroundColor: "#EBF155",
      color: "text.primary",
    },
    "&:disabled": {
      // backgroundColor: "#23222D",
      borderWidth: "0px",
      color: "text.accent",
    },
  },
  accentTitle: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "12px",
    color: "text.accent",
  },
  subTitle: {
    fontFamliy: "Manrope",
    fontSize: "24px",
    fontWeight: 700,
    color: "text.primary",
  },
  smallTitle: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    color: "text.primary",
  },
  mediumTitle: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
  },
  pageTitle: {
    fontFamliy: "Manrope",
    fontSize: "32px",
    fontWeight: 700,
    color: "text.primary",
  },
  bigTitle: {
    fontFamliy: "Manrope",
    fontSize: "64px",
    fontWeight: 400,
    lineHeight: "87px",
    color: "text.primary",
    letterSpacing: "-2%",
  },
  superTitle: {
    fontFamily: "Manrope",
    fontWeight: "400",
    fontSize: "48px",
    lineHeight: "54px",
    letterSpacing: "-2%",
  },
  smallContent: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: "text.primary",
  },
  mediumContent: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#23222D",
  },
  pinLeft: {},
  grid: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      padding: "0 !important",
      backgroundColor: "#F7F6F3",
      boxShadow: "-2px 0px 4px 0px rgba(35, 34, 45, 0.20)",
    },
    "& .MuiDataGrid-pinnedColumns": {
      "& .Mui-hovered": {
        backgroundColor: "white",
      },
      "& div": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      boxShadow: "-2px 0px 4px 0px rgba(35, 34, 45, 0.20)",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-root": {
      border: "none",
      borderCollapse: "separate",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      border: "none",
      color: "background.stone",
      fontFamily: "Manrope",
    },
    "& .MuiStack-root": {
      display: "ruby",
    },
    "& .MuiDataGrid-container--top [role=row]": {
      background: "transparent",
    },
    "& .MuiDataGrid-row": {
      borderBottom: "1px solid black",
      borderColor: "text.tertiary",
      backgroundColor: "text.contrast",
      "&:hover": {
        backgroundColor: "#F8FAFC",
      },
      "&:active": {
        cursor: "pointer",
        backgroundColor: "#EFF5F9",
      },
    },
    "& .MuiDataGrid-row:first-of-type": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& .MuiDataGrid-row:last-of-type": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
      fontSize: "14px",
      fontFamily: "Manrope",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-cell .MuiStack-root": {
      display: "flex",
      lineHeight: "normal",
    },
    "& .MuiDataGrid-overlayWrapper": {
      minHeight: "60px",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      minHeight: "60px",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
  },
  gridCollection: {
    border: "none",
    "& .MuiDataGrid-main": {
      "& > div:last-of-type": {
        display: "none",
      },
    },
    // "& .MuiDataGrid-columnHeaders": {
    //   border: "none",
    // },
    // "& .MuiDataGrid-footerContainer": {
    //   border: "none",
    // },
    // "& .MuiDataGrid-root": {
    //   border: "none",
    //   borderCollapse: "separate",
    // },
    // "& .MuiDataGrid-columnsContainer": {
    //   border: "none",
    //   // backgroundColor: '#f9f9f9',
    // },
    // "& .MuiDataGrid-columnHeader": {
    //   border: "none",
    //   color: "background.stone",
    //   fontFamily: "Manrope",
    //   // backgroundColor: '#f9f9f9',
    //   // fontWeight: 'bold',
    // },
    // "& .MuiDataGrid-row": {
    //   borderBottom: "1px solid black",
    //   borderColor: "text.tertiary",
    //   backgroundColor: "text.contrast",
    //   "&:hover": {
    //     cursor: "pointer",
    //   },
    // },
    // "& .MuiDataGrid-row:first-of-type": {
    //   borderTopLeftRadius: "10px",
    //   borderTopRightRadius: "10px",
    // },
    // "& .MuiDataGrid-row:last-of-type": {
    //   borderBottomLeftRadius: "10px",
    //   borderBottomRightRadius: "10px",
    //   border: "none",
    // },
    // "& .MuiDataGrid-cell": {
    //   border: "none",
    //   fontSize: "14px",
    //   fontFamily: "Manrope",
    //   fontWeight: 400,
    //   // borderRight: '1px solid #ccc',
    // },
    // "& .MuiDataGrid-overlayWrapper": {
    //   minHeight: "60px",
    // },
    // "& .MuiDataGrid-virtualScrollerRenderZone": {
    //   minHeight: "60px",
    // },
    // "& .MuiDataGrid-cell:focus-within": {
    //   outline: "none",
    // },
  },
  pagination: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: "500",
    lineHeight: "150%",
    color: "background.stone",
  },
  textInput: {
    borderRadius: "8px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #EBF15580 !important",
    },
    "&.Mui-disabled ": {
      backgroundColor: "#EEECE780",
      "& input": {
        WebkitTextFillColor: "text.primary",
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
    },
  },
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8.5px",
      backgroundColor: "text.contrast",
    },

    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #EBF15580 !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "text.contrast",
    },
  },
  bottomTips: {
    color: "text.accent",
    fontSize: "10px",
  },
  bottomError: {
    fontSize: "12px",
    color: "warnning.secondary",
  },
  cAutoComplete: {
    "& .MuiAutocomplete-hasClearIcon": {
      "& .MuiInputBase-root": {
        paddingRight: "8px",
        height: "40px",
      },

      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
    },
    "& .MuiIconButton-root": {
      height: "28px !important",
    },
    borderRadius: "8px",
  },
  link: {
    color: "#6293BC",
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: "500",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonInCard: {
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "#6293BC",
    "&:hover": {
      cursor: "pointer",
      color: "#7783A1",
    },
    "&:active": {
      color: "#00195A",
    },
  },
  optionMenu: {
    "& .MuiPaper-root": { borderRadius: "8px" },

    "& ul": {
      paddingY: "0px",
      "& li": {
        paddingY: "13px",
        paddingLeft: "16px",
        borderBottom: "1px solid #F8F8F8",
        "&:active": {
          backgroundColor: "#F8F8F8",
        },
        "&:hover": {
          backgroundColor: "#EBF15566",
        },
      },
    },
  },

  dayOffdays: {
    border: "1px solid #9F9E9E",
    borderRadius: "4px",
    padding: "5px 6px",
    fontSize: "12px",
    letterSpacing: "0.6px",
    textTransform: "uppercase",
    lineHeight: "12px",
    fontFamily: "Manrope",
    textAlign: "center",
  },
  buttonUpload: {
    backgroundColor: "text.primary",
    color: "#C0D4E4",
    fontFamily: "Manrope",
    fontSize: "16px",
    lineHeight: "14px",
    padding: "7px 10px",
    borderRadius: "8px",
    "& svg": { fill: "#C0D4E4" },
    "&:hover": {
      color: "#C0D4E4",
      backgroundColor: "#464A53",
      "& svg": { fill: "#C0D4E4" },
    },
    "&:active": {
      backgroundColor: "#131313",
    },
    "&:focus": {
      backgroundColor: "text.primary",
      outline: "2px solid #EBF15580",
    },
  },
  selectOrganization: {
    borderRadius: "8px",
    backgroundColor: "background.lighter",
    padding: "24px",
    gap: "12px",
    color: "#282828",
    "&:hover": {
      backgroundColor: "#EFF5F9",
    },
  },

  enterOrganization: {
    backgroundColor: "none",
    color: "text.primary",
    fontSize: "16px",
    fontFamily: "Manrope",
    "&:hover": {
      color: "text.secondary",
    },
  },
  noOrganization: {
    padding: "24px 28px",
    backgroundColor: "#F7F6F3",
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    fontFamily: "Manrope",
  },
  organization: {
    padding: "16px",
    borderRadius: "8px",
    fontSize: "20px",
    backgroundColor: "#F7F6F3",
    fontWeight: 500,
    "& > div:last-of-type": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "#EFF5F9",
      cursor: "pointer",
      "& > div:last-of-type": {
        display: "flex",
      },
    },
  },
  popup: {
    gap: "22px",
    padding: "34px 42px",
    borderRadius: "8px",
    backgroundColor: "text.contrast",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "450px", // Full width on extra small screens
      sm: "500px", // 50% width on small screens
      md: "550px", // 33.33% width on medium screens
      lg: "600px", // 25% width on large screens
    },
    overflowY: "auto",
    maxHeight: "90vh",
  },
  locationPopup: {
    gap: "22px",
    padding: "34px 42px",
    borderRadius: "8px",
    backgroundColor: "text.contrast",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "450px", // Full width on extra small screens
      sm: "600px", // 50% width on small screens
      md: "1050px", // 33.33% width on medium screens
      lg: "1100px", // 25% width on large screens
    },
  },
  checkBox: {
    "& svg": {
      fill: "#9F9E9E",
    },
    "&:hover": {
      backgroundColor: "#EBF1554D",
      "& svg": {
        fill: "black",
      },
    },
    "&:focus": {
      backgroundColor: "#EBF1554D",
    },
    "&.Mui-checked": {
      "& svg": {
        // fill: "#2CB53B",
        fill: "#18ACFF",
        stroke: "white",
      },
    },
  },
  wizardTitle: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#9F9E9E",
    letterSpacing: "0.36px",
    textTransform: "uppercase",
    lineHeight: "normal",
  },
  wizardCard: {
    width: "268px",
    padding: "24px",
    borderRadius: "8px",
    backgroundColor: "#FAFAF8",
  },
  wizardEmpty: {
    width: "400px",
    maxWidth: "400px",
    padding: "24px 28px",
    backgroundColor: "#F7F6F3",
    borderRadius: "8px",
  },
  wizardEmptyTitle: {
    fontSize: "32px",
    fontWeight: "400",
    color: "#282828",
    mt: "16px",
    mb: "8px",
    lineHeight: "normal",
  },
  wizardEmptyContent: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
  },
  normalWizard: {
    width: "672px",
    maxWidth: "672px",
    padding: "24px",
    backgroundColor: "#F7F6F3",
    borderRadius: "8px",
  },
  normalWizardActive: {
    width: "672px",
    maxWidth: "672px",
    padding: "24px",
    backgroundColor: "#EFF5F9",
    borderRadius: "8px",
  },
  normalWizardTitle: {
    fontSize: "32px",
    fontWeight: "400",
    color: "#282828",
    my: "8px",
  },
  normalWizardContent: {
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Manrope",
    lineHeight: "24px",
    width: "534px",
    maxWidth: "534px",
  },
  placeItem: {
    transform: "translate(-50%,-50%);",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      padding: "2px",
    },
  },
  placeModal: {
    gap: "22px",
    padding: "34px 42px",
    borderRadius: "8px",
    backgroundColor: "text.contrast",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min-content",
    maxWidth: {
      xs: "450px", // Full width on extra small screens
      sm: "600px", // 50% width on small screens
      md: "850px", // 33.33% width on medium screens
      lg: "1000px", // 25% width on large screens
    },
  },
  comingSoonModal: {
    padding: "30px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "text.contrast",
    border: "0px",
    zIndex: 2,
    borderRadius: "8px",
    width: "600px",
  },
  dangerZone: {
    color: "rgba(174, 115, 1, 1)",
    border: "1px solid",
    padding: "20px",
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  buttonDelete: {
    height: "40px",
    borderRadius: "8px",
    color: "rgba(174, 115, 1, 1)",
    border: "1px solid",
    minWidth: "78px",
  },
};
