import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import EditWaybillDialog from "./dialogs/EditWaybillDialog";
import RejectWaybillDialog from "./dialogs/RejectWaybillDialog";
import DistributeQuantityDialog from "./dialogs/DistributeQuantityDialog";
import { PageTitle } from "../../../components/Typography";
import { DataTable } from "../../../components/Table";
import { SearchBar } from "../../../components/CustomInput";
import { CardPrimary } from "../../../components/Card";
import { StateSpan } from "../../../components/StateSpan";
import {
  setCurrentTransferId,
  setEditQuantity,
  setRejectReason,
  setTransferResume,
} from "../../../slices/transferSlice";
import {
  fetchTransfersList,
  rejectTransfers,
  validateTransfers,
} from "../../../actions/transfer";
import { getDateStringFromNum, showNotification } from "../../../utils/helpers";
import { STATE_REJECTED, STATE_VALIDATED } from "../../../utils/type";
import { DRIVER, SALES, SALES_MANAGER } from "../../../utils/constants";
import { classes } from "../../../utils/class";

const TransferListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transferData = useSelector((state) => state.transfer.transfer);
  const orgData = useSelector((state) => state.organizations.profile);
  const role = useSelector((state) => state.user.role);
  const total_collected = useSelector(
    (state) => state.transfer.total_collected
  );

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [rejectDlgOpen, setRejectDlgOpen] = useState(false);
  const [bulkRejectDlgOpen, setBulkRejectDlgOpen] = useState(false);
  const [disQuanDlgOpen, setDisQuanDlgOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);

  useEffect(() => {
    dispatch(
      fetchTransfersList(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmEdit = (quantity) => {
    dispatch(setEditQuantity(quantity));
    navigate(`/transfer-detail/${current}/edit`);
  };

  const handleConfirmReject = (description) => {
    dispatch(setRejectReason(description));
    navigate(`/transfer-detail/${current}/reject`);
  };

  const handleConfirmBulkReject = (description) => {
    dispatch(rejectTransfers({ ids: itemsChecked, reason: description }));
    setBulkRejectDlgOpen(false);
  };

  const handleConfirmDistributeQuantity = () => {
    dispatch(
      setTransferResume(
        transferData.filter((row) => itemsChecked.indexOf(row._id) !== -1)
      )
    );
    navigate("/transfer-distribute-resume");
  };

  const handleBulkActions = (action) => {
    if (role !== DRIVER) {
      if (!itemsChecked.length) {
        showNotification("Please select transfers", "error");
        return;
      }
      if (action === "validate") {
        if (role === SALES_MANAGER || role === SALES || role === DRIVER) {
          showNotification("You don't have permission", "error");
        } else dispatch(validateTransfers({ ids: itemsChecked }));
      } else if (action === "reject") setBulkRejectDlgOpen(true);
      else if (action === "distribute") setDisQuanDlgOpen(true);
    }
  };

  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(transferData.map((t) => t._id));
            }
          }}
        />
      ),
      width: 60,
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row._id) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row._id);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row._id);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {getDateStringFromNum(props.row.date, orgData.timezone)}
        </span>
      ),
    },
    {
      field: "origin_name",
      headerName: "Origin",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 400,
      renderCell: (props) => {
        const { quantity, ratio } = props.row;
        return (
          <Stack direction="row" spacing={1} className="mui-ellipsis">
            <Typography sx={{ width: "60px" }}>{quantity} kg</Typography>
            <Typography sx={{ color: ratio >= 0 ? "green" : "red" }}>
              {ratio > 0 && "+"}
              {ratio}%
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "vehicle_driver_name",
      headerName: "Driver",
      width: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (props) => {
        if ([STATE_VALIDATED, STATE_REJECTED].indexOf(props.row.status) !== -1)
          return <StateSpan status={props.row.status} />;
        return (
          <Stack direction="row">
            <IconButton
              onClick={(e) => {
                setCurrent(props.row._id);
                setEditDlgOpen(true);
                e.stopPropagation();
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setCurrent(props.row._id);
                navigate(`/transfer-detail/${props.row._id}/validate`);
                e.stopPropagation();
              }}
              disabled={
                role === SALES_MANAGER || role === SALES || role === DRIVER
              }
            >
              <DoneOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setCurrent(props.row._id);
                setRejectDlgOpen(true);
                e.stopPropagation();
              }}
              disabled={
                role === SALES_MANAGER || role === SALES || role === DRIVER
              }
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: 3,
        gap: "8px",
        paddingX: "60px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Transfer</PageTitle>

        <Stack direction="row">
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => navigate("/add-transfer-log")}
          >
            Add Transfer
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº Transfers"
                content={transferData.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Total Collected"
                content={(Number(total_collected) * 1000)?.toLocaleString(
                  "en-US"
                )}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px", width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              label="Search transfer"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "origin_name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton width={"100%"} />
          )}
        </Box>
      </Box>
      <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
        {isLoaded ? (
          <Button
            variant="outlined"
            sx={classes.buttonMoreOption}
            onClick={(e) => {
              setAnchorEL(e.currentTarget);
              setOpen(!open);
            }}
          >
            Bulk actions
            <KeyboardArrowDownIcon />
          </Button>
        ) : (
          <Skeleton width={"162px"} height={"46px"} />
        )}
        {itemsChecked.length > 0 && (
          <>
            <Typography>{itemsChecked.length} selected transfers</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {(() => {
                let sum = 0;
                for (let i = 0; i < itemsChecked.length; i++)
                  sum += transferData.find(
                    (t) => t._id === itemsChecked[i]
                  ).quantity;
                return sum;
              })()}{" "}
              kg
            </Typography>
          </>
        )}
        <Menu
          id="basic-menu"
          open={open}
          anchorEl={anchorEL}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            onClick={() => handleBulkActions("validate")}
            disabled={role === DRIVER}
          >
            Validate Transfers
          </MenuItem>
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            onClick={() => handleBulkActions("reject")}
          >
            Reject Transfers
          </MenuItem>
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            onClick={() => handleBulkActions("distribute")}
          >
            Distribute quantity
          </MenuItem>
        </Menu>
      </Stack>
      <Box>
        {isLoaded ? (
          <DataTable
            breakpoint="xl"
            rows={transferData}
            columns={columns}
            sx={classes.grid}
            filterModel={filterModel}
            onRowClick={(params, events, details) => {
              dispatch(setCurrentTransferId(params.row.id));
              navigate(`/transfer-detail/${params.row.id}/view`);
            }}
          />
        ) : (
          <Skeleton width={"100%"} height={"200px"} />
        )}
      </Box>
      <EditWaybillDialog
        open={editDlgOpen}
        defaultQuantity={
          transferData.find((transfer) => transfer._id === current)?.quantity
        }
        handleClose={() => setEditDlgOpen(false)}
        handleConfirm={handleConfirmEdit}
      />
      <RejectWaybillDialog
        open={rejectDlgOpen}
        handleClose={() => setRejectDlgOpen(false)}
        handleConfirm={handleConfirmReject}
      />
      <RejectWaybillDialog
        open={bulkRejectDlgOpen}
        handleClose={() => setBulkRejectDlgOpen(false)}
        handleConfirm={handleConfirmBulkReject}
      />
      <DistributeQuantityDialog
        open={disQuanDlgOpen}
        handleClose={() => setDisQuanDlgOpen(false)}
        handleConfirm={handleConfirmDistributeQuantity}
      />
    </Box>
  );
};

export default TransferListPage;
