import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../components/Typography";
import { classes } from "../../../utils/class";
import {
  PURPOSE_TYPES,
  getDateStringFromNum,
  showNotification,
} from "../../../utils/helpers";
import { fetchVisitDetail } from "../../../actions/visit";

const VisitsDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, detail } = useSelector((state) => state.visits.selected);
  const orgData = useSelector((state) => state.organizations.profile);

  useEffect(() => {
    if (!id) navigate("/visits");
    dispatch(fetchVisitDetail(id));
  }, [id, navigate, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "23px",
        gap: "8px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Visits</PageTitle>
        <Stack>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => navigate("/add-visit-log")}
          >
            Add log
          </Button>
        </Stack>
      </Box>
      <Stack sx={{ alignItems: "center" }} spacing={3}>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Identification
            </Typography>
            <Stack direction="row">
              <Typography sx={{ width: "120px" }}>Purpose</Typography>
              <Typography>
                {PURPOSE_TYPES[detail.purpose] || detail.purpose}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "120px" }}>Date</Typography>
              <Typography>
                {getDateStringFromNum(detail.date, orgData.timezone)}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "120px" }}>Origin</Typography>
              <Typography>{detail.pickup_point_name}</Typography>
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Transfer
            </Typography>
            <Stack direction="row">
              <Typography sx={{ width: "120px" }}>Raw Waste</Typography>
              <Typography>{detail.raw_waste_name}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "120px" }}>Quantity</Typography>
              {detail.quantity && <Typography>{detail.quantity} kg</Typography>}
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Collection Details
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Stack direction="row">
                  <Typography sx={{ width: "120px", fontSize: "12px" }}>
                    Vehicle license plate
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {detail.vehicle_plate}{" "}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: "120px", fontSize: "12px" }}>
                    VAT Number
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {detail.vehicle_vat_num}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: "120px", fontSize: "12px" }}>
                    Driver
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {detail.vehicle_driver}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row">
                  <Typography sx={{ width: "120px", fontSize: "12px" }}>
                    Destination
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {detail.destination_warehouse_name}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: "120px", fontSize: "12px" }}>
                    VAT Number
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {detail.destination_warehouse_vat_num}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              sx={{ marginTop: "30px", justifyContent: "space-between" }}
            >
              <Stack>
                <Typography sx={{ fontWeight: "bold", width: "120px" }}>
                  Waybill
                </Typography>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/wtn-detail/${detail.transfer}`)}
                >
                  {detail.waybill_number}
                </Typography>
              </Stack>
              {detail.purpose === "waste_pickup" && (
                <Button
                  sx={classes.buttonSave}
                  onClick={() => {
                    if (!detail.transfer) {
                      showNotification("Transfer does not exist", "error");
                      return;
                    }
                    navigate(`/transfer-detail/${detail.transfer}/view`);
                  }}
                >
                  View transfer
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Notes
            </Typography>
            <Typography sx={{ color: "grey" }}>{detail.notes}</Typography>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Notes for next pickups
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {detail.notes_for_next}
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default VisitsDetailPage;
