import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";

import { PageTitle } from "../../../components/Typography";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { classes } from "../../../utils/class";
import { showNotification } from "../../../utils/helpers";
import OrganizationService from "../../../services/OrganizationService";

const Balance = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const qPageIndex = Number(params.get("page")) || 0;
  const qPageSize = Number(params.get("limit")) || 10;
  const qSortField = params.get("sort") || "date";
  const qSortOrder = params.get("order") || 1;
  const qSearch = params.get("search") || "";

  const [sortModel, setSortModel] = useState([
    {
      field: qSortField || "date",
      sort: qSortOrder || "asc",
    },
  ]);

  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("excel");
  const [isDownloading, setIsDownloading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: qPageIndex - 1,
    pageSize: qPageSize,
  });

  const { data: transactionsInfo, isLoadingTransactionsInfo } = useQuery({
    queryKey: ["transactionsInfo"],
    queryFn: async () => {
      try {
        const res = await OrganizationService.getTransactionsInfo();
        if (res?.data?.message === "success") {
          return res?.data?.data;
        } else {
          showNotification("Failed to get transactions info", "error");
        }
      } catch (e) {
        showNotification("Failed to get transactions info", "error");
      }
    },
  });

  const { data: transactions, isLoading: isLoadingTransactions } = useQuery({
    queryKey: [
      "transactions",
      qPageIndex,
      qPageSize,
      qSortField,
      qSortOrder,
      qSearch,
    ],
    queryFn: async () => {
      try {
        const data = {
          page: qPageIndex,
          limit: qPageSize,
          ...(qSortField && {
            sort: {
              [qSortField]: qSortOrder === "asc" ? 1 : -1,
            },
          }),
          ...(qSearch && {
            search: qSearch,
          }),
        };
        const res = await OrganizationService.getTransactionsList(data);
        if (res?.data?.message === "success") {
          return res?.data?.data;
        } else {
          showNotification("Failed to get transactions history", "error");
        }
      } catch (e) {
        showNotification("Failed to get transactions history", "error");
      }
    },
  });

  const handleChangePagination = (model) => {
    setPaginationModel(model);
  };

  const handleChange = (e) => {
    setType(e.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    const res = await OrganizationService.generateTransactionReport(type);
    setIsDownloading(false);
    setOpenModal(false);

    console.log("res", res);

    if (res?.data?.data) {
      window.open(res?.data?.data, "_blank");
    }
  };

  const columns = [
    {
      field: "created_at",
      flex: 1,
      minWidth: 150,
      headerName: "Date",
      renderCell: ({ row }) => {
        const formattedDate = moment(row?.created_at).format("YYYY-MM-DD");
        return <Stack key={`date-${row?._id}`}>{formattedDate}</Stack>;
      },
    },
    {
      field: "action",
      headerName: "Transaction Type",
      flex: 1,
      minWidth: 150,
      renderCell: (props) => {
        const capitalizedValue =
          props?.value.charAt(0).toUpperCase() + props?.value.slice(1);
        return <span>{capitalizedValue}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (props) => {
        if (props?.value >= 0) {
          return (
            <span
              style={{
                color: "#005609",
              }}
            >
              +{props?.value?.toLocaleString("en-US")}
            </span>
          );
        } else {
          return (
            <span
              style={{
                color: "#A60814",
              }}
            >
              {props?.value?.toLocaleString("en-US")}
            </span>
          );
        }
      },
    },
    {
      field: "_id",
      headerName: "Transaction ID",
      flex: 1,
      renderCell: (props) => <span>{props.value}</span>,
    },
  ];

  useEffect(() => {
    const newParams = new URLSearchParams(params);
    newParams.set("page", paginationModel.page + 1);
    newParams.set("limit", paginationModel.pageSize);
    setParams(newParams);
  }, [params, paginationModel, setParams]);

  return (
    <>
      <Stack pt={4} px={4}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <PageTitle>Balance History</PageTitle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => navigate("/dashboard")}
            >
              Back
            </Button>
            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Export History
            </Button>
          </Stack>
        </Stack>
        <Stack pt={6} direction="row" gap={1}>
          {isLoadingTransactionsInfo ? (
            <Skeleton variant="rounded" width="162px" height="86px" />
          ) : (
            <CardPrimary
              type={1}
              title="Nº Schedules"
              content={transactionsInfo?.total_transactions || 0}
            />
          )}
          {isLoadingTransactionsInfo ? (
            <Skeleton variant="rounded" width="162px" height="86px" />
          ) : (
            <CardPrimary
              type={3}
              title="Total Recharge"
              content={
                transactionsInfo?.total_deposit_amount?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                ) || "$0"
              }
            />
          )}
          {isLoadingTransactionsInfo ? (
            <Skeleton variant="rounded" width="162px" height="86px" />
          ) : (
            <CardPrimary
              type={4}
              title="Total WTN Deduction"
              content={
                transactionsInfo?.total_transfer_amount?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                ) || "$0"
              }
            />
          )}
        </Stack>
        <Stack pt={4}>
          {isLoadingTransactionsInfo ? (
            <Skeleton variant="rounded" height="30px" width="275px" />
          ) : (
            <SearchBar
              placeholder="Search WTN Number"
              sx={{
                width: "309px",
              }}
              onSearchChange={(e) => {
                const newParams = new URLSearchParams(params);
                if (e) {
                  newParams.set("search", e);
                } else {
                  newParams.delete("search");
                }
                setParams(newParams);
              }}
            />
          )}
        </Stack>
        <Stack pt={2}>
          {isLoadingTransactions || isLoadingTransactionsInfo ? (
            <Skeleton width="100%" height="200px" />
          ) : (
            <DataGrid
              rows={transactions}
              columns={columns}
              rowHeight={60}
              getRowId={(row) => row?._id}
              sx={{
                ...classes.grid,
              }}
              loading={isLoadingTransactions}
              pagination={true}
              paginationMode="server"
              paginationModel={paginationModel}
              pageSizeOptions={[10, 25, 50, 100]}
              sortModel={sortModel}
              onSortModelChange={(model) => {
                const newParams = new URLSearchParams(params);
                if (model?.length) {
                  newParams.set("sort", model[0]?.field);
                  newParams.set("order", model[0]?.sort);
                } else {
                  newParams.delete("sort");
                  newParams.delete("order");
                }
                setParams(newParams);
                setSortModel(model);
              }}
              onPaginationModelChange={handleChangePagination}
              rowCount={transactionsInfo?.total_transactions || 0}
              disableColumnFilter
            />
          )}
        </Stack>
      </Stack>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: 470,
            borderRadius: "8px",
            padding: "48px",
          }}
        >
          <Stack position="relative">
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              gap: 3,
            }}
          >
            <Typography
              width={330}
              sx={{
                fontSize: 32,
                fontWeight: 700,
              }}
            >
              Export Balance History
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Select your file format option below
            </Typography>
            <Stack>
              <FormControl>
                <RadioGroup
                  name="download"
                  value={type}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="excel"
                    control={<Radio />}
                    label="Excel"
                    sx={{
                      margin: 0,
                      height: 58,
                      backgroundColor: type === "excel" ? "#F7F6F3" : "#FFF",
                    }}
                  />
                  <FormControlLabel
                    value="pdf"
                    control={<Radio />}
                    label="PDF"
                    sx={{
                      margin: 0,
                      height: 58,
                      backgroundColor: type === "pdf" ? "#F7F6F3" : "#FFF",
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              gap={2}
              direction="row"
              justifyContent="space-between"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "14px",
                paddingTop: "8px",
              }}
            >
              <Button
                sx={{
                  ...classes.buttonCancel,
                  height: 40,
                  borderRadius: "8px",
                }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{
                  ...classes.buttonSave,
                  height: 40,
                  borderRadius: "8px",
                  width: 98,
                }}
                onClick={handleDownload}
                loading={isDownloading}
              >
                {!isDownloading && "Download"}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default Balance;
