import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Card,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import dayjs from "dayjs";
import moment from "moment-timezone";

import { CDateInput, CSelect } from "../../../components/CustomInput";
import { StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import PickupService from "../../../services/PickupService";
import UserService from "../../../services/UserService";
import ZoneSerivce from "../../../services/ZoneService";
import ProducerService from "../../../services/ProducerService";
import OrganizationService from "../../../services/OrganizationService";
import {
  setAvailableRawWastes,
  setDashboardInfo,
} from "../../../slices/orgSlice";
import { setPickupList } from "../../../slices/pickupSlice";
import { setZoneList } from "../../../slices/zoneSlice";
import { setUserRole } from "../../../slices/userSlice";
import { summaryString } from "../../../utils/string";
import { SUCCESS } from "../../../utils/type";
import { classes } from "../../../utils/class";
import { ERROR } from "../../../utils/constants";
import {
  showNotification,
  getServerTimestamp,
  getDateStringFromNum,
  PURPOSE_TYPES,
} from "../../../utils/helpers";
import { fetchOrganizationInfo } from "../../../actions/organizations";

const colors = [
  "#021B58",
  "#EBF066",
  "#77829F",
  "#475A82",
  "#9F9D9E",
  "#22222C",
];
const today = new Date();

const MyCard = ({ children, title, value, fullHeight = false }) => {
  return (
    <Card
      sx={[
        fullHeight && {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
        {
          padding: "25px",
          borderRadius: "20px",
          boxShadow: "none",
          // width: "100%",
        },
      ]}
    >
      <div>
        <Typography sx={{ fontSize: "20px" }}>{title}</Typography>
        {value !== "-" && (
          <Typography
            sx={{ fontSize: "30px", fontWeight: "bold", marginBottom: "0px" }}
          >
            {value}
          </Typography>
        )}
      </div>
      {children}
    </Card>
  );
};

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashInfo = useSelector((state) => state.organizations.dashboard);
  const pickupList = useSelector((state) => state.pickup.pickupList);
  const rawWastes = useSelector(
    (state) => state.organizations.availableRawWastes
  );
  const zones = useSelector((state) => state.zone.zoneList);
  const visitsData = useSelector((state) => state.visits.scheduled_visits);
  const orgData = useSelector((state) => state.organizations.profile);

  const getTop5Visits = (visits) => {
    return [...visits].sort((a, b) => b.date - a.date).slice(0, 5);
  };

  const [topVisits, setTopVisits] = useState([]);
  const [producersYAxis, setProducersYAxis] = useState([]);
  const [pickupYAxis, setPickupYAxis] = useState([]);
  const [visitsYAxis, setVisitsYAxis] = useState([]);
  const [scheduledYAxis, setScheduledYAxis] = useState([]);
  const [vsYAxis, setVsYAxis] = useState([]);
  const [vsYAxis1, setVsYAxis1] = useState([]);
  const [vsWaste, setVsWaste] = useState(null);
  const [vsZone, setVsZone] = useState(null);
  const [scheduleWaste, setScheduleWaste] = useState(null);
  const [schedulePickup, setSchedulePickup] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState("This year");
  const [rangeDlgOpen, setRangeDlgOpen] = useState(false);
  const [fromRangeDate, setFromRangeDate] = useState(dayjs(new Date()));
  const [toRangeDate, setToRangeDate] = useState(dayjs(new Date()));
  const [isLoaded, setIsLoaded] = useState(false);
  const [openVisitActions, setOpenVisitActions] = useState(false);
  const [openTransactionActions, setOpenTransactionActions] = useState(false);
  const [loadStatus, setLoadStatus] = useState({
    pickup: false,
    waste: false,
    zone: false,
  });

  const visitActionsButtonRef = useRef(null);
  const transactionActionsButtonRef = useRef(null);

  const { data: transactions } = useQuery({
    queryKey: ["transactions"],
    queryFn: async () => {
      try {
        const data = {
          page: 1,
          limit: 5,
          sort: {
            created_at: -1,
          },
        };
        const res = await OrganizationService.getTransactionsList(data);
        if (res?.data?.message === "success") {
          return res?.data?.data;
        } else {
          showNotification("Failed to get transactions history", "error");
        }
      } catch (e) {
        showNotification("Failed to get transactions history", "error");
      }
    },
  });

  useEffect(() => {
    if (visitsData?.length > 0) {
      const tempVisits = getTop5Visits(visitsData);
      setTopVisits(tempVisits);
    }
  }, [visitsData]);

  useEffect(() => {
    dispatch(fetchOrganizationInfo());

    ProducerService.fetchProducerList()
      .then((res) => {
        if (res.data.data.data.length === 0) navigate("/auth/reviewing");
      })
      .catch((err) => {});

    UserService.fetchRole()
      .then((res) => {
        dispatch(setUserRole(res.data.data));
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (orgData.timezone) {
      setStartDate(
        getServerTimestamp(
          new Date(today.getFullYear(), 0, 1).getTime(),
          orgData.timezone
        )
      );
      setEndDate(
        getServerTimestamp(
          new Date(today.getFullYear(), 12, 0).getTime(),
          orgData.timezone
        )
      );
    }
  }, [orgData]);

  useEffect(() => {
    PickupService.fetchPickupPoints()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setPickupList(res.data.data.data));
          if (res.data.data.data.length > 0)
            setSchedulePickup(res.data.data.data[0].name);
          setLoadStatus((prev) => {
            return {
              ...prev,
              pickup: true,
            };
          });
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
    OrganizationService.fetchAvailableRawWastes()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setAvailableRawWastes(res.data.data));
          if (res.data.data.length > 0) {
            setVsWaste(res.data.data[0].name);
            setScheduleWaste(res.data.data[0].name);
          }
          setLoadStatus((prev) => {
            return {
              ...prev,
              waste: true,
            };
          });
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || err.response.data.message
            : "Error occured",
          "error"
        );
      });
    ZoneSerivce.fetchZones()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setZoneList(res.data.data.data));
          if (res.data.data.data.length > 0)
            setVsZone(res.data.data.data[0].name);
          setLoadStatus((prev) => {
            return {
              ...prev,
              zone: true,
            };
          });
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  }, []);

  useEffect(() => {
    if (!dashInfo) return;

    setProducersYAxis(
      dashInfo.producer_history.map((history) => history.count)
    );
    setPickupYAxis(
      dashInfo.pickup_point_history.map((history) => history.count)
    );
    setVisitsYAxis(
      dashInfo.total_visit_history.map((history) => history.count)
    );
    setScheduledYAxis(
      dashInfo.scheduled_visits_in_3_days.map((history) => history.count)
    );
    let collected_expected = [...dashInfo.collected_expected_wastes_history];
    collected_expected.sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? 1 : -1
    );
    setVsYAxis(
      collected_expected.map((history) => history.collected_quantity || 0)
    );
    setVsYAxis1(
      collected_expected.map((history) => history.expected_quantity || 0)
    );
  }, [dashInfo]);

  useEffect(() => {
    if (loadStatus.pickup && loadStatus.waste && loadStatus.zone)
      try {
        OrganizationService.fetchDashboardInfo({
          start: startDate,
          end: endDate,
          collected_waste: rawWastes.find((waste) => waste.name === vsWaste)
            ?.rawWasteId,
          collected_zone: zones.find((zone) => zone.name === vsZone)?._id,
          scheduled_pickup_point: pickupList.find(
            (pickup) => pickup.name === schedulePickup
          )?._id,
          scheduled_waste: rawWastes.find(
            (waste) => waste.name === scheduleWaste
          )?.rawWasteId,
        })
          .then((res) => {
            if (res.data.message === SUCCESS) {
              dispatch(setDashboardInfo(res.data.data));
              setIsLoaded(true);
            }
          })
          .catch((err) => {});
      } catch (err) {}
  }, [loadStatus]);

  const onConfirmRange = () => {
    setStartDate(
      getServerTimestamp(new Date(fromRangeDate).getTime(), orgData.timezone)
    );
    setEndDate(
      getServerTimestamp(new Date(toRangeDate).getTime(), orgData.timezone)
    );
    setRangeDlgOpen(false);
  };

  const onChangeDateRange = (v) => {
    setDateRange(v);
    if (v === "Today") {
      setStartDate(getServerTimestamp(new Date().getTime(), orgData.timezone));
      setEndDate(getServerTimestamp(new Date().getTime(), orgData.timezone));
    } else if (v === "Yesterday") {
      let yesterday = new Date(new Date());
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(getServerTimestamp(yesterday.getTime(), orgData.timezone));
      setEndDate(getServerTimestamp(yesterday.getTime(), orgData.timezone));
    } else if (v === "This week") {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay();

      let firstday = getServerTimestamp(
        new Date(curr.setDate(first)).getTime(),
        orgData.timezone
      );
      setStartDate(firstday);
      setEndDate(curr);
    } else if (v === "Last week") {
      let today = new Date();
      let startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setDate(today.getDate() - today.getDay());
      let endOfPreviousWeek = new Date(startOfCurrentWeek);
      endOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 1);
      let startOfPreviousWeek = new Date(endOfPreviousWeek);
      startOfPreviousWeek.setDate(endOfPreviousWeek.getDate() - 6);
      setStartDate(
        getServerTimestamp(startOfPreviousWeek.getTime(), orgData.timezone)
      );
      setEndDate(
        getServerTimestamp(endOfPreviousWeek.getTime(), orgData.timezone)
      );
    } else if (v === "This month") {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let startOfMonth = new Date(year, month - 1, 1);
      setStartDate(
        getServerTimestamp(startOfMonth.getTime(), orgData.timezone)
      );
      setEndDate(today);
    } else if (v === "Last month") {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth();
      let startOfLastMonth = new Date(year, month - 1, 1);
      let endOfLastMonth = new Date(year, month, 0);
      setStartDate(
        getServerTimestamp(startOfLastMonth.getTime(), orgData.timezone)
      );
      setEndDate(
        getServerTimestamp(endOfLastMonth.getTime(), orgData.timezone)
      );
    } else if (v === "This year") {
      let today = new Date();
      let year = today.getFullYear();
      let start = new Date(year, 0, 1);
      setStartDate(getServerTimestamp(start.getTime(), orgData.timezone));
      setEndDate(today);
    } else if (v === "Range") {
      setRangeDlgOpen(true);
    }
  };

  const renderGraph = (
    totalData,
    value,
    data,
    lineColor,
    textColor = "#000000"
  ) => {
    let min = 9999999999,
      max = 0;
    for (let i = 0; i < data.length; i++) {
      if (min > data[i]) min = data[i];
      if (max < data[i]) max = data[i];
    }
    // const gap = Math.min((300 - 10 * data.length) / data.length, 20);
    const gap = 20;
    const distance = data.length ? data[data.length - 1] - data[0] : 0;

    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            width: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: "30px", fontWeight: "bold", color: textColor }}
          >
            {value}
          </Typography>
          {distance !== 0 && (
            <span>
              <Typography
                sx={{
                  padding: "5px",
                  backgroundColor: lineColor,
                  color: "white",
                  borderRadius: "10px",
                  width: "fit-content",
                  marginBottom: "5px",
                }}
              >
                {`${distance > 0 ? "+" : distance < 0 ? "-" : ""}${distance}`}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: textColor }}>
                {dateRange}
              </Typography>
            </span>
          )}
        </Box>
        <Box maxWidth={"300px"} overflow={"auto"} className="graphCard">
          <Stack
            gap={`${gap}px`}
            direction="row"
            sx={{
              width: "fit-content",
              height: "120px",
              alignItems: "flex-end",
            }}
          >
            {data.map((value, idx) => (
              <Tooltip
                key={idx}
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                arrow
                title={
                  <Stack
                    padding={1}
                    gap={1}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>Date:&nbsp;</Box>
                      <Box>{totalData[idx]?.date}</Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>Value:&nbsp;</Box>
                      <Box>{value}</Box>
                    </Stack>
                  </Stack>
                }
                placement="top"
              >
                <Box
                  // position={"relative"}
                  sx={{
                    backgroundColor: lineColor,
                    "&:hover": {
                      cursor: "pointer",
                      "& *": {
                        display: "block",
                      },
                    },
                    borderTopLeftRadius: "3px",
                    borderTopRightRadius: "3px",
                  }}
                  width={10}
                  bottom={0}
                  height={
                    20 + 80 * (max === min ? 1 : (value - min) / (max - min))
                  }
                ></Box>
              </Tooltip>
            ))}
          </Stack>
        </Box>
      </Stack>
    );
  };

  const renderCollectedExpectedGraph = () => {
    let min = 9999999999,
      max = 0;
    for (let i = 0; i < vsYAxis.length; i++) {
      if (min > vsYAxis1[i]) min = vsYAxis1[i];
      if (min > vsYAxis[i]) min = vsYAxis[i];
      if (max < vsYAxis[i]) max = vsYAxis[i];
      if (max < vsYAxis1[i]) max = vsYAxis1[i];
    }
    const gap = Math.min(500 / vsYAxis.length, 30);

    return (
      <Box overflow={"auto"} className="graphCard">
        <Stack
          gap={`${gap}px`}
          direction="row"
          sx={{ height: "120px", alignItems: "flex-end", width: "fit-content" }}
        >
          {vsYAxis.map((value, idx) => {
            let h1 =
                20 +
                80 *
                  (max === min
                    ? 1
                    : (Math.max(value, vsYAxis1[idx]) - min) / (max - min)),
              h2 =
                20 +
                80 *
                  (max === min
                    ? 1
                    : (Math.min(value, vsYAxis1[idx]) - min) / (max - min));

            if (Math.max(value, vsYAxis1[idx]) - min === 0) h1 = 5;
            if (Math.min(value, vsYAxis1[idx]) - min === 0) h2 = 5;

            return (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                arrow
                title={
                  <Stack
                    padding={1}
                    gap={1}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>Date:&nbsp;</Box>
                      <Box>
                        {dashInfo.collected_expected_wastes_history[idx]?.date}
                      </Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>
                        Expected Amount:&nbsp;
                      </Box>
                      <Box>{vsYAxis1[idx]}</Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>
                        Collected Amount:&nbsp;
                      </Box>
                      <Box>{value}</Box>
                    </Stack>
                  </Stack>
                }
                placement="top"
              >
                <Box
                  position={"relative"}
                  sx={{
                    backgroundColor:
                      value >= vsYAxis1[idx] ? "#EBF155" : "#00195A",
                    "&:hover": {
                      cursor: "pointer",
                      "& *": {
                        display: "block",
                      },
                    },
                    borderTopLeftRadius: "3px",
                    borderTopRightRadius: "3px",
                  }}
                  width={15}
                  bottom={0}
                  height={h1}
                >
                  <Box
                    sx={{
                      backgroundColor:
                        value >= vsYAxis1[idx] ? "#00195A" : "#EBF155",
                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                    }}
                    width={15}
                    position={"absolute"}
                    bottom={0}
                    height={h2}
                  ></Box>
                </Box>
              </Tooltip>
            );
          })}
        </Stack>
      </Box>
    );
  };

  const visitColumns = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        return <StateSpan status={props.row.status} />;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {`${getDateStringFromNum(props.row.date, orgData.timezone)}`}
        </span>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination Warehouse",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {props.row.destination_warehouse_name || ""}
        </span>
      ),
    },
    {
      field: "transfer",
      headerName: "Expected Transfer",
      width: 400,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "100px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "zone",
      headerName: "Zone",
      width: 180,
      renderCell: (props) => {
        let zones = [];
        if (props.row.zones.length > 0) {
          props.row.zones.map((item, index) => {
            return zones.push(item?.name);
          });
        }
        let zoneData = zones.join(", ");
        return <span className="mui-ellipsis">{zoneData}</span>;
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      width: 150,
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {props.row.vehicles[0]?.reg_plate || ""}
          </span>
        );
      },
    },
    {
      field: "driver_name",
      headerName: "Driver",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.row.driver_name || ""}</span>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      renderCell: (props) => {
        return (
          <>
            <div
              style={{ width: "60px", display: "flex", alignItems: "center" }}
            >
              {props.row.notes && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.notes}
                  placement="top"
                >
                  <SpeakerNotesOutlinedIcon />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const transactionColumns = [
    {
      field: "created_at",
      flex: 1,
      minWidth: 150,
      headerName: "Date",
      renderCell: ({ row }) => {
        const formattedDate = moment(row?.created_at).format("YYYY-MM-DD");
        return <Stack key={`date-${row?._id}`}>{formattedDate}</Stack>;
      },
    },
    {
      field: "action",
      headerName: "Transaction Type",
      flex: 1,
      minWidth: 150,
      renderCell: (props) => {
        const capitalizedValue =
          props?.value.charAt(0).toUpperCase() + props?.value.slice(1);
        return <span>{capitalizedValue}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (props) => {
        if (props?.value >= 0) {
          return (
            <span
              style={{
                color: "#005609",
              }}
            >
              +{props?.value?.toLocaleString("en-US")}
            </span>
          );
        } else {
          return (
            <span
              style={{
                color: "#A60814",
              }}
            >
              {props?.value?.toLocaleString("en-US")}
            </span>
          );
        }
      },
    },
    {
      field: "_id",
      headerName: "Transaction ID",
      flex: 1,
      renderCell: (props) => <span>{props.value}</span>,
    },
  ];

  return (
    <Box sx={{ margin: "30px", marginX: "50px" }}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", marginBottom: "20px" }}
      >
        <span></span>
        <Stack>
          <CSelect
            sx={{ width: "fit-content" }}
            value={dateRange}
            onSelectChange={onChangeDateRange}
            options={[
              "Today",
              "Yesterday",
              "This week",
              "Last week",
              "This month",
              "Last month",
              "This year",
              "Range",
            ]}
          />
        </Stack>
      </Stack>

      <Stack
        gap={5}
        flexDirection={"row"}
        mb={3}
        sx={{
          flexWrap: {
            xs: "wrap",
            sm: "wrap",
            md: "wrap", // No wrap on extra small screens
            lg: "nowrap", // Wrap on small screens and above
            xl: "nowrap",
          },
        }}
      >
        <Grid container spacing={5}>
          <Grid item sm={12} md={6} lg={4}>
            {isLoaded ? (
              <Card
                sx={{
                  padding: "25px",
                  borderRadius: "20px",
                  boxShadow: "none",
                  background: "#ffffff",
                  // minWidth: "200px",
                }}
              >
                <Stack sx={{ height: "50px", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: "20px", height: "30px" }}>
                    Total producers
                  </Typography>
                </Stack>
                {renderGraph(
                  dashInfo.producer_history,
                  producersYAxis[producersYAxis.length - 1] || 0,
                  producersYAxis,
                  "#C0D4E4"
                )}
              </Card>
            ) : (
              <Skeleton variant="rounded" width={"300px"} height={"220px"} />
            )}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {isLoaded ? (
              <Card
                sx={{
                  padding: "25px",
                  borderRadius: "20px",
                  boxShadow: "none",
                  background: "#C0D4E4",
                  // minWidth: "300px",
                }}
              >
                <Stack sx={{ height: "50px", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: "20px", height: "30px" }}>
                    Total collection points
                  </Typography>
                </Stack>
                {renderGraph(
                  dashInfo.pickup_point_history,
                  pickupYAxis[pickupYAxis.length - 1] || 0,
                  pickupYAxis,
                  "#00195A"
                )}
              </Card>
            ) : (
              <Skeleton variant="rounded" width={"300px"} height={"220px"} />
            )}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {isLoaded ? (
              <Card
                sx={{
                  padding: "25px",
                  borderRadius: "20px",
                  boxShadow: "none",
                  background: "#00195A",
                  // minWidth: "200px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "50px",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", color: "#C0D4E4" }}>
                    Total visits
                  </Typography>
                </Stack>
                {renderGraph(
                  dashInfo.total_visit_history || [],
                  visitsYAxis[visitsYAxis.length - 1] || 0,
                  visitsYAxis,
                  "#C0D4E4",
                  "#C0D4E4"
                )}
              </Card>
            ) : (
              <Skeleton variant="rounded" width={"300px"} height={"220px"} />
            )}
          </Grid>
        </Grid>
      </Stack>

      {/* Balance Overview */}
      <Stack mb={3}>
        {isLoaded ? (
          <Card
            sx={[
              {
                padding: "25px",
                borderRadius: "20px",
                boxShadow: "none",
              },
            ]}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack mb={orgData?.available_balance < 50 ? 4 : 11}>
                    <Typography sx={{ fontSize: "20px" }}>
                      Balance Overview
                    </Typography>
                    <Typography py={2} fontSize={36} fontWeight={700}>
                      {orgData?.available_balance?.toLocaleString() || 0}{" "}
                      Credits
                    </Typography>
                    <Button
                      sx={classes.buttonPrimary}
                      onClick={() => navigate("/recharge-balance")}
                    >
                      Recharge
                    </Button>
                  </Stack>
                  {orgData?.available_balance < 50 && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      sx={{
                        borderLeft: "4px solid #D64C56",
                        background: "#F8F8F8",
                        padding: "16px 24px",
                      }}
                    >
                      <WarningAmberIcon
                        style={{
                          width: 16,
                          fill: "#D64C56",
                        }}
                      />
                      <Typography
                        color="#D64C56"
                        fontSize={16}
                        fontWeight={500}
                      >
                        Low Balance: <b>Recharge Now</b>
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography sx={{ fontSize: "20px" }}>
                    Recent Transactions
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      padding: "10px 22px",
                      border: "solid 1px #BEBEC3",
                      color: "#363546",
                      borderRadius: "40px",
                      boxSizing: "border-box",
                      fontSize: 12,
                      height: 32,
                      "&:hover": {
                        borderColor: "black",
                      },
                    }}
                    endIcon={<KeyboardArrowDownIcon scale={1} />}
                    onClick={() => {
                      setOpenTransactionActions(true);
                    }}
                    ref={transactionActionsButtonRef}
                  >
                    Actions
                  </Button>
                  <Menu
                    id="transaction-actions-menu"
                    open={openTransactionActions}
                    onClose={() => setOpenTransactionActions(false)}
                    anchorEl={transactionActionsButtonRef.current}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiList-root": {
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                  >
                    <MenuItem
                      key="view-all-transactions"
                      onClick={() => {
                        setOpenTransactionActions(false);
                        navigate("/balance");
                      }}
                      sx={{
                        minWidth: "126px",
                        padding: "15px",
                        "&:active": {
                          backgroundColor: "#F8F8F8",
                        },
                        "&:hover": {
                          backgroundColor: "#EBF15566",
                        },
                        display: "flex",
                      }}
                    >
                      View Balance History
                    </MenuItem>
                  </Menu>
                </Stack>
                <DataTable
                  fit="100%"
                  breakpoint="xl"
                  rows={transactions}
                  columns={transactionColumns}
                  sx={{
                    "& .MuiDataGrid-footerContainer": {
                      display: "none !important",
                    },
                  }}
                  getRowId={(row) => row._id}
                />
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Skeleton variant="rounded" width={"100%"} height={"325px"} />
        )}
      </Stack>

      {/* Scheduled Collections */}
      <Stack mb={3}>
        {isLoaded ? (
          <Card
            sx={[
              {
                padding: "25px",
                borderRadius: "20px",
                boxShadow: "none",
              },
            ]}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ fontSize: "20px" }}>
                Scheduled Collections
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "10px 22px",
                  border: "solid 1px #BEBEC3",
                  color: "#363546",
                  borderRadius: "40px",
                  boxSizing: "border-box",
                  fontSize: 12,
                  height: 32,
                  "&:hover": {
                    borderColor: "black",
                  },
                }}
                endIcon={<KeyboardArrowDownIcon scale={1} />}
                onClick={() => {
                  setOpenVisitActions(true);
                }}
                ref={visitActionsButtonRef}
              >
                Actions
              </Button>
              <Menu
                id="visit-actions-menu"
                open={openVisitActions}
                onClose={() => setOpenVisitActions(false)}
                anchorEl={visitActionsButtonRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiList-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              >
                <MenuItem
                  key="view-all-collections"
                  onClick={() => {
                    setOpenVisitActions(false);
                    navigate("/visits");
                  }}
                  sx={{
                    minWidth: "126px",
                    padding: "15px",
                    "&:active": {
                      backgroundColor: "#F8F8F8",
                    },
                    "&:hover": {
                      backgroundColor: "#EBF15566",
                    },
                    display: "flex",
                  }}
                >
                  View all Collections
                </MenuItem>
                <MenuItem
                  key="schedule-a-collection"
                  onClick={() => {
                    setOpenVisitActions(false);
                    navigate("/add-visit-log");
                  }}
                  sx={{
                    minWidth: "126px",
                    padding: "15px",
                    "&:active": {
                      backgroundColor: "#F8F8F8",
                    },
                    "&:hover": {
                      backgroundColor: "#EBF15566",
                    },
                    display: "flex",
                  }}
                >
                  Schedule a Collection
                </MenuItem>
              </Menu>
            </Stack>
            <DataTable
              fit="100%"
              breakpoint="xl"
              rows={topVisits}
              columns={visitColumns}
              sx={{
                "& .MuiDataGrid-footerContainer": {
                  display: "none !important",
                },
                height: 365,
              }}
            />
          </Card>
        ) : (
          <Skeleton variant="rounded" width={"100%"} height={"325px"} />
        )}
      </Stack>

      <Grid container gap={3}>
        <Grid item container gap={3} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} lg={5.8}>
            {isLoaded ? (
              <MyCard
                title="Total waste collected vs expected"
                value={vsYAxis1[vsYAxis1.length - 1] || 0}
                fullHeight
              >
                <Grid container spacing={3}>
                  <Grid xs={4} item>
                    <CSelect
                      label="Waste"
                      placeholder="Cooking oil"
                      value={vsWaste}
                      onSelectChange={(v) => setVsWaste(v)}
                      options={rawWastes.map((waste) => waste.name)}
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <CSelect
                      label="Zone"
                      placeholder="All zones"
                      value={vsZone}
                      onSelectChange={(v) => setVsZone(v)}
                      options={zones.map((zone) => zone.name)}
                    />
                  </Grid>
                </Grid>
                {renderCollectedExpectedGraph()}
                <Stack sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#EBF155",
                        width: "10px",
                        height: "10px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    Collected Waste
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#00195A",
                        width: "10px",
                        height: "10px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    Expected Waste
                  </Stack>
                </Stack>
              </MyCard>
            ) : (
              <Skeleton variant="rounded" width={"100%"} height={474} />
            )}
          </Grid>
          <Grid item container xs={12} lg={5.8}>
            <Stack sx={{ width: "100%" }} gap={3}>
              {isLoaded ? (
                <MyCard
                  title="Expiring documents in 30 days"
                  value={dashInfo.expirint_documents_in_30_days}
                />
              ) : (
                <Skeleton variant="rounded" width={"100%"} height={"125px"} />
              )}
              {isLoaded ? (
                <MyCard
                  title="Collection points per zone"
                  value={dashInfo.percentage_pickup_points_per_zone.reduce(
                    (total, current) => total + current.count,
                    0
                  )}
                >
                  <PieChart
                    colors={colors}
                    series={[
                      {
                        innerRadius: 30,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        outerRadius: 80,
                        data: dashInfo.percentage_pickup_points_per_zone.map(
                          (info, index) => ({
                            label: info.zone_name,
                            value: info.count,
                          })
                        ),
                      },
                    ]}
                    margin={{ right: 150 }}
                    width={400}
                    height={200}
                  />
                </MyCard>
              ) : (
                <Skeleton variant="rounded" width={"100%"} height={"325px"} />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Stack
          flexDirection={"row"}
          gap={5}
          flexWrap={"wrap"}
          mt={3}
          width="100%"
        >
          <Grid container spacing={6}>
            <Grid item sm={12} md={12} lg={6}>
              {isLoaded ? (
                <MyCard
                  title="Total waste transfer"
                  value={`${dashInfo.total_waste_transfer
                    .reduce((total, current) => total + current.quantity, 0)
                    .toLocaleString("en")}kg`}
                >
                  <PieChart
                    colors={colors}
                    series={[
                      {
                        innerRadius: 30,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        outerRadius: 80,
                        data: dashInfo.total_waste_transfer.map((row) => ({
                          label: summaryString(row.raw_waste_name, 15),
                          value: row.quantity,
                        })),
                      },
                    ]}
                    margin={{ right: 350 }}
                    width={600}
                    height={200}
                  />
                </MyCard>
              ) : (
                <Skeleton variant="rounded" width={"100%"} height={"325px"} />
              )}
            </Grid>
            <Grid item sm={12} md={12} lg={6}>
              {isLoaded ? (
                <MyCard title="Schedule visits in 3 days" value="-">
                  <Stack
                    gap={2}
                    flexDirection={"row"}
                    // minWidth={"400px"}
                    height={125}
                  >
                    <CSelect
                      label="Waste"
                      placeholder=""
                      value={scheduleWaste}
                      onSelectChange={(v) => setScheduleWaste(v)}
                      options={rawWastes.map((waste) => waste.name)}
                    />

                    <CSelect
                      label="Collection Point"
                      placeholder=""
                      value={schedulePickup}
                      onSelectChange={(v) => setSchedulePickup(v)}
                      options={pickupList.map((pickup) => pickup.name)}
                    />
                  </Stack>

                  {renderGraph(
                    dashInfo.scheduled_visits_in_3_days,
                    null,
                    scheduledYAxis,
                    "#00195A",
                    "black",
                    false
                  )}
                </MyCard>
              ) : (
                <Skeleton variant="rounded" width={"100%"} height={"325px"} />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Modal open={rangeDlgOpen} onClose={() => setRangeDlgOpen(false)}>
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: "500px",
            padding: "40px",
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }} gap={1}>
            From:{" "}
            <CDateInput
              value={fromRangeDate}
              onChange={(v) => setFromRangeDate(v)}
            />
            To:{" "}
            <CDateInput
              value={toRangeDate}
              onChange={(v) => setToRangeDate(v)}
            />
          </Stack>
          <Stack sx={{ justifyContent: "center", marginTop: "20px" }}>
            <Button sx={classes.buttonPrimary} onClick={onConfirmRange}>
              Filter
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardPage;
