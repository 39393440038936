import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import EditWaybillDialog from "./dialogs/EditWaybillDialog";
import { PageTitle, SubTitle } from "../../../components/Typography";
import { classes } from "../../../utils/class";
import { DRIVER } from "../../../utils/constants";
import { PURPOSE_TYPES, getDateStringFromNum } from "../../../utils/helpers";
import {
  editTransferWaybill,
  fetchTransferDetails,
  rejectTransfers,
  validateTransfers,
} from "../../../actions/transfer";
import { fetchOrganizationInfo } from "../../../actions/organizations";
import TransferService from "../../../services/TransferService";
import { setEditQuantity } from "../../../slices/transferSlice";

const TransferDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = params;

  const rejectReason = useSelector((state) => state.transfer.reject_reason);
  const editQuantity = useSelector((state) => state.transfer.edit_quantity);
  const { details } = useSelector((state) => state.transfer.current);
  const orgData = useSelector((state) => state.organizations.profile);
  const role = useSelector((state) => state.user.role);
  const transferData = useSelector((state) => state.transfer.transfer);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const { data: balanceDetails } = useQuery({
    queryKey: ["balanceDetails", id],
    queryFn: async () => {
      const res = await TransferService.getBalanceDetails(id);
      if (res?.data?.message === "success") {
        return res.data.data;
      } else {
        return {};
      }
    },
  });

  useEffect(() => {
    dispatch(fetchTransferDetails(id));
    dispatch(fetchOrganizationInfo());
  }, [id, dispatch]);

  useEffect(() => {
    if (orgData?.available_balance < 50) {
      setShowWarning(true);
    } else if (
      type === "edit" &&
      orgData?.credit_per_kg * (editQuantity - details?.quantity) >
        orgData?.available_balance
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [details, editQuantity, orgData, type]);

  useEffect(() => {
    if (type === "edit")
      dispatch(editTransferWaybill({ ids: [id], quantity: editQuantity }));
  }, [type, dispatch, id, editQuantity]);

  const handleProceedValidation = async () => {
    if (role !== DRIVER) {
      try {
        await dispatch(validateTransfers({ ids: [id] }, navigate));
        await dispatch(fetchOrganizationInfo());
      } catch (e) {
      } finally {
        setConfirmModalOpen(false);
      }
    } else {
      setConfirmModalOpen(false);
    }
  };

  const onClickReject = () => {
    dispatch(rejectTransfers({ ids: [id], reason: rejectReason }, navigate));
  };

  const handleClickValidate = () => {
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleClickEdit = () => {
    setEditDlgOpen(true);
  };

  const handleConfirmEdit = (quantity) => {
    dispatch(setEditQuantity(quantity));
    setEditDlgOpen(false);
    navigate(`/transfer-detail/${id}/edit`);
  };

  const totalCredits =
    type === "edit"
      ? editQuantity * orgData?.credit_per_kg || 0
      : orgData?.credit_per_kg * details?.quantity || 0;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "23px",
          gap: "8px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 5,
          }}
        >
          <PageTitle>Transfer Confirmation</PageTitle>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            {type === "view" ? (
              <>
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  sx={{ ...classes.buttonCancel, border: "none" }}
                  onClick={() => navigate("/transfer")}
                >
                  Cancel
                </Button>
                {type === "reject" && (
                  <Button
                    variant="outlined"
                    sx={classes.buttonPrimary}
                    onClick={onClickReject}
                  >
                    <CloseOutlinedIcon />
                    Reject
                  </Button>
                )}
                {type !== "reject" && (
                  <>
                    <Button
                      variant="outlined"
                      sx={classes.buttonCancel}
                      onClick={handleClickEdit}
                      disabled={role === DRIVER}
                    >
                      <CreateOutlinedIcon
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                      &nbsp;Edit
                    </Button>
                    <Button
                      variant="outlined"
                      sx={classes.buttonPrimary}
                      onClick={handleClickValidate}
                      disabled={role === DRIVER || showWarning}
                    >
                      <CheckIcon
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                      &nbsp;Validate
                    </Button>
                  </>
                )}
              </>
            )}
          </Stack>
        </Box>

        <Stack sx={{ alignItems: "center" }} spacing={3}>
          {type === "edit" && (
            <Card
              sx={{
                width: "500px",
                padding: "20px",
                backgroundColor: "#DEDEDE",
              }}
            >
              <SubTitle>Edited data</SubTitle>
              <Typography>Quantity:</Typography>
              <Typography>
                <s>{`${details.quantity}kg`}</s> {editQuantity}kg
              </Typography>
            </Card>
          )}
          {type === "reject" && (
            <Card
              sx={{
                width: "500px",
                padding: "20px",
                backgroundColor: "#DEDEDE",
              }}
            >
              <SubTitle>Reject Reason</SubTitle>
              <Typography sx={{ fontWeight: "bold" }}>Other</Typography>
              <Typography>{rejectReason}</Typography>
            </Card>
          )}
          <Stack p={3} borderRadius="6px" bgcolor="#464A53" width={500}>
            <Typography fontSize={20} fontWeight={700} color="#FFF">
              Credit Deduction Calculation
            </Typography>
            <Typography fontSize={16} fontWeight={500} color="#FFF" pt={1.5}>
              {orgData?.credit_per_kg || 0} credits per kg
            </Typography>
            {type === "edit" && (
              <>
                <Stack
                  pt={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize={16} fontWeight={500} color="#FFF">
                    Held Credits:
                  </Typography>
                  <Typography fontSize={20} fontWeight={700} color="#FFF">
                    {(
                      orgData?.credit_per_kg * details?.quantity || 0
                    )?.toLocaleString("en-US") || 0}{" "}
                    credits
                  </Typography>
                </Stack>
                <Stack
                  pt={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize={16} fontWeight={500} color="#FFF">
                    Credits Adjustment:
                  </Typography>
                  <Typography fontSize={20} fontWeight={700} color="#FFF">
                    {(
                      orgData?.credit_per_kg *
                      (editQuantity - details?.quantity)
                    )?.toLocaleString("en-US") || 0}{" "}
                    credits
                  </Typography>
                </Stack>
              </>
            )}
            <Stack
              pt={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize={16} fontWeight={700} color="#FFF">
                Total Credits:
              </Typography>
              <Typography fontSize={20} fontWeight={700} color="#FFF">
                {totalCredits?.toLocaleString("en-US")} credits
              </Typography>
            </Stack>
            {showWarning && (
              <Stack mt={4} py={3} px={2} bgcolor="#EEB7BB">
                <Typography fontSize={12} fontWeight={700} color="#282828">
                  YOUR CURRENT BALANCE
                </Typography>
                <Typography
                  pt={1}
                  fontSize={20}
                  fontWeight={700}
                  color="#23222D"
                >
                  {orgData?.available_balance?.toLocaleString("en-US") || 0}{" "}
                  Credits
                </Typography>
                <Stack
                  mt={3}
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    borderLeft: "4px solid #D64C56",
                    background: "#F8F8F8",
                    padding: "16px 24px",
                  }}
                >
                  <WarningAmberIcon
                    style={{
                      width: 16,
                      fill: "#D64C56",
                    }}
                  />
                  <Typography color="#D64C56" fontSize={16} fontWeight={500}>
                    Low Balance: <b>Recharge Now</b>
                  </Typography>
                </Stack>
                <Stack pt={3}>
                  <Button
                    sx={classes.buttonPrimary}
                    onClick={() => navigate("/recharge-balance")}
                  >
                    Recharge Now
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Identification
              </Typography>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Purpose</Typography>
                <Typography>{PURPOSE_TYPES[details.purpose]}</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Date</Typography>
                <Typography>
                  {getDateStringFromNum(details.date, orgData.timezone)}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Origin</Typography>
                <Typography>{details.origin_name}</Typography>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Collection Details
              </Typography>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Raw Waste</Typography>
                <Typography>{details.raw_waste_name}</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Quantity</Typography>
                <Typography>{details.quantity} kg</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px", minWidth: "200px" }}>
                  Operation Code
                </Typography>
                <Typography>
                  {details.operation_code} -{" "}
                  {details.operation_code_description}
                </Typography>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Transport
              </Typography>
              <Grid container>
                <Stack direction="row">
                  <Typography sx={{ width: "200px" }}>
                    Vehicle license plate
                  </Typography>
                  <Typography>{details.vehicle_reg_plate}</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: "200px" }}>VAT Number</Typography>
                  <Typography>{details.vehicle_vat_num}</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: "200px" }}>Driver</Typography>
                  <Typography>{details.vehicle_driver_name}</Typography>
                </Stack>
              </Grid>
            </Stack>
          </Card>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Destination
              </Typography>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Destination</Typography>
                <Typography>{details.destination_warehouse_name}</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>VAT Number</Typography>
                <Typography>{details.destination_warehouse_vat_num}</Typography>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Waybill
              </Typography>
              <Typography
                sx={{ color: "grey", cursor: "pointer" }}
                onClick={() => navigate(`/wtn-detail/${details._id}`)}
              >
                {details.waybill_number}
              </Typography>
            </Stack>
          </Card>
          <Card sx={{ width: "500px", padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Notes
              </Typography>
              <Typography sx={{ color: "grey" }}>
                {details.notes || "No Notes"}
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Box>
      <Modal open={confirmModalOpen} onClose={handleCloseConfirmModal}>
        <Stack sx={{ ...classes.popup, width: 470 }}>
          <Stack>
            <PageTitle>Confirm Action</PageTitle>
          </Stack>
          <IconButton
            onClick={handleCloseConfirmModal}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography fontSize={16} fontWeight={500}>
            Confirming this Transfer will deduct{" "}
            <b>
              {type === "edit"
                ? (
                    orgData?.credit_per_kg *
                    (editQuantity - details?.quantity)
                  )?.toLocaleString("en-US") || 0
                : balanceDetails?.deduct_credits?.toLocaleString("en-US") ||
                  0}{" "}
              credits
            </b>{" "}
            from your balance once the transfer is completed. Your remaining
            balance will be{" "}
            <b>
              {type === "edit"
                ? (
                    orgData?.available_balance -
                    orgData?.credit_per_kg * (editQuantity - details?.quantity)
                  )?.toLocaleString("en-US")
                : balanceDetails?.remain_balance?.toLocaleString("en-US") ||
                  0}{" "}
              credits
            </b>
            .
            <br />
            <br />
            If the transfer is not confirmed within 48 hours the balance will
            automatically be dedicated.
          </Typography>

          <Stack gap={2} direction="row" justifyContent="space-between">
            <Button sx={classes.buttonCancel} onClick={handleCloseConfirmModal}>
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={handleProceedValidation}>
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <EditWaybillDialog
        open={editDlgOpen}
        defaultQuantity={
          transferData.find((transfer) => transfer._id === id)?.quantity
        }
        handleClose={() => setEditDlgOpen(false)}
        handleConfirm={handleConfirmEdit}
      />
    </>
  );
};

export default TransferDetailPage;
