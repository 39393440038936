import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSnackbar } from "notistack";

import { CSelect } from "../components/CustomInput";
import { UcoIcon } from "../components/icons/menu_icons/icons";
import { MENU_NAV } from "../utils/constants";
import { snackbarRef } from "../utils/helpers";
import { get2Names } from "../utils/string";
import { signOut } from "../actions/user";
import { resetAllOrgData } from "../actions/format";
import {
  fetchOrganizationInfo,
  selectOrganization,
} from "../actions/organizations";
import logo from "../assets/images/logo.svg";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const links = [
  ["dashboard"],
  ["balance"],
  ["producers", "add-producer", "producers-detail"],
  ["add-collection", "collection", "collection-detail"],
  ["warehouse", "add-warehouse", "warehouse-profile"],
  ["add-vehicle", "vehicle", "vehicle-detail"],
  // ["inventory", "add-inventory", "inventory-sublist"],
  ["zones", "add-zone", "manage-collection", "zones-detail"],
  ["routes"],
  ["certificates"],
  ["visits", "visits-detail", "add-visit-log"],
  ["transfer", "transfer-detail", "trasnfer-distribute-resume"],
  ["tickets", "add-tickets"],
  ["settings"],
];

export default function MainLayout() {
  snackbarRef.current = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthenticated, profile } = useSelector((state) => state.user);
  const isUploading = useSelector((state) => state.spinner.isUploading);
  const isLoading = useSelector((state) => state.spinner.isLoading);
  const data = useSelector((state) => state.organizations.invites);
  const orgInfo = useSelector((state) => state.organizations.profile);

  const [open, setOpen] = React.useState(true);
  const [navIndex, setNav] = useState(-1);
  const [updatingOrg, setUpdatingOrg] = useState(false);

  const invitations = data.filter((invitation, index) => {
    return invitation.status === "accepted";
  });

  useEffect(() => {
    let path = location.pathname.split("/")[1];
    links.forEach((link, index) => {
      if (link.indexOf(path) !== -1) {
        setNav(index);
      }
    });
  }, [location]);

  useEffect(() => {
    setUpdatingOrg(false);
  }, [orgInfo]);

  useEffect(() => {
    dispatch(fetchOrganizationInfo());
  }, [dispatch]);

  const handleDrawerSelectio = () => {
    setOpen(!open);
  };

  return (
    <>
      {isAuthenticated && invitations.length > 0 && (
        <>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <Drawer
              variant="permanent"
              open={open}
              sx={{
                width: open ? drawerWidth : "88px !important",

                "& .MuiDrawer-paperAnchorDockedLeft ": {
                  width: open ? drawerWidth : "88px",
                },
                position: "relative",
                height: "100vh",
              }}
            >
              <DrawerHeader>
                {open ? (
                  <Stack paddingY={"20px"} paddingX="30px">
                    <img src={logo} alt="logo" />
                  </Stack>
                ) : (
                  <Stack paddingY={"30px"}>
                    <UcoIcon />
                  </Stack>
                )}

                <IconButton
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                  onClick={handleDrawerSelectio}
                >
                  {!open ? (
                    <ChevronRightIcon sx={{ color: "#C0D4E4" }} />
                  ) : (
                    <ChevronLeftIcon sx={{ color: "#C0D4E4" }} />
                  )}
                </IconButton>
              </DrawerHeader>

              {open && (
                <Stack paddingX={"30px"} marginBottom={"20px"}>
                  <CSelect
                    menuSx={{
                      "& .MuiList-root": {
                        background: "#23222D",
                        color: "#C0D4E4",
                      },
                    }}
                    sx={{
                      borderRadius: "8px",
                      background: "none",
                      fontWeight: 500,
                      fontFamily: "Manrope",
                      fontSize: "14px",
                      color: "#C0D4E4",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C0D4E4 !important",
                        borderWidth: "1px !important",
                      },
                      "& .MuiSvgIcon-root": { color: "#C0D4E4 !important" },
                    }}
                    value={orgInfo?.company_name}
                    onSelectChange={(value) => {
                      //should be udpated later
                      for (let invite of invitations) {
                        if (invite.org_name === value) {
                          dispatch(resetAllOrgData());
                          dispatch(
                            selectOrganization(
                              {
                                org_id: invite.org,
                              },
                              () => {
                                dispatch(fetchOrganizationInfo());
                              },
                              () => {
                                dispatch(fetchOrganizationInfo());
                              }
                            )
                          );
                          setUpdatingOrg(true);
                          break;
                        }
                      }
                    }}
                    options={invitations.map((invitation) => {
                      return invitation.org_name;
                    })}
                  />
                </Stack>
              )}

              {/* Balance Menu */}
              {open && (
                <Stack
                  direction="column"
                  gap={1.5}
                  my={2}
                  py={2}
                  px={3}
                  bgcolor="#131313"
                >
                  <Stack
                    gap={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      width={200}
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      Balance:{" "}
                      <b>
                        {orgInfo?.available_balance?.toLocaleString() || 0}{" "}
                        Credits
                      </b>
                    </Typography>
                  </Stack>
                  <Stack>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        borderRadius: "8px",
                        color: "#C0D4E4",
                        backgroundColor: "#23222D",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => {
                        navigate("/recharge-balance");
                      }}
                    >
                      Recharge
                    </Button>
                  </Stack>
                </Stack>
              )}

              <List
                sx={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: open ? "calc(100vh - 450px)" : "calc(100vh - 157px)",
                }}
              >
                {MENU_NAV.map((menu, index) => (
                  <ListItem
                    onClick={() => navigate(menu.url)}
                    key={menu.title}
                    disablePadding
                    sx={{
                      display: "block",
                      paddingY: "12px",
                      "& > div": {
                        background: "none !important",
                      },
                      "&:hover": {
                        backgroundColor:
                          index === navIndex ? "#EBF1551A" : "#C0D4E41A",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        alignItems: "center",
                        px: "30px",
                        py: 0,
                        color: index === navIndex ? "#EBF155" : "#C0D4E4",
                        borderLeft:
                          index === navIndex
                            ? "4px solid"
                            : "4px #00000000 solid",
                        "&:hover": {
                          background: "none !important",
                        },
                      }}
                    >
                      <Tooltip
                        slotProps={{
                          tooltip: {
                            sx: {
                              background: "black",
                            },
                          },
                          arrow: {
                            sx: {
                              color: "black",
                            },
                          },
                        }}
                        title={menu.title}
                        placement="right"
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {React.cloneElement(menu.icon, {
                            fill: index === navIndex ? "#EBF155" : "#C0D4E4",
                          })}
                        </ListItemIcon>
                      </Tooltip>
                      {open && (
                        <ListItemText
                          primary={menu.title}
                          sx={{
                            color: index === navIndex ? "#EBF155" : "#C0D4E4",
                          }}
                          primaryTypographyProps={{
                            style: {
                              fontWeight: index === navIndex ? 700 : 500,
                            },
                          }}
                        />
                      )}
                    </Stack>
                  </ListItem>
                ))}
              </List>

              {/*bottom*/}
              <Stack
                py={2}
                width={"100%"}
                position={"absolute"}
                bottom={0}
                left={0}
                sx={{
                  borderTop: open ? "1px solid #C0D4E4" : "0px",
                }}
              >
                <Stack
                  justifyContent={"flex-start"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  sx={{
                    paddingLeft: "30px",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      fontSize: "10px",
                      fontFamily: "Manrope",
                      textAlign: "center",
                      backgroundColor: "#424752",
                      color: "#C0D4E4",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {get2Names(profile?.name || profile?.email)}
                  </Box>
                  {open && (
                    <Box
                      sx={{
                        maxWidth: "140px",
                        whiteSpace: "wrap",
                        overflowWrap: "anywhere",
                      }}
                    >
                      {(isAuthenticated && profile?.name) || profile?.email}
                    </Box>
                  )}
                </Stack>

                {open && (
                  <>
                    <Button
                      sx={{
                        color: "white",
                        textAlign: "left",
                        width: "100px",
                        marginLeft: "50px",
                        fontSize: "12px",
                      }}
                      onClick={() => {
                        dispatch(signOut());
                      }}
                    >
                      Sign out
                    </Button>

                    <Stack
                      sx={{
                        color: "#C0D4E4",
                        fontSize: "12px",
                        marginLeft: "30px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      © 2022 Uco software - v2.0.23.0
                    </Stack>
                  </>
                )}
              </Stack>
            </Drawer>

            {/* main content */}
            <Box
              component="main"
              sx={{ flexGrow: 1, padding: "0px 0px 0px 0px" }}
            >
              <div
                style={{
                  position: "absolute",
                  color: "black",
                  zIndex: "-1",
                }}
              >
                <svg
                  width="90%"
                  height="90%"
                  viewBox="0 0 669 614"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M667.166 224.81C661.716 173.159 615.288 135.82 563.658 141.168C511.978 146.615 474.518 192.968 479.968 244.619C480.563 250.413 480.91 256.306 480.91 262.149C480.91 352.427 407.428 425.868 317.099 425.868C226.77 425.868 153.288 352.427 153.288 262.149C153.288 256.306 153.586 250.462 154.18 244.718C154.18 244.52 154.18 244.321 154.18 244.123C162.653 160.927 232.964 97.688 316.653 97.9851C368.531 98.1832 410.797 56.3375 411.045 4.4884C411.293 -47.4102 369.423 -89.7016 317.495 -89.9988C268.59 -90.2464 228.257 -53.1547 223.45 -5.51496C214.978 77.7308 144.815 141.118 61.1262 140.821C13.2614 140.524 -27.9639 176.477 -33.0675 225.058C-34.3558 237.289 -35 249.818 -35 262.199C-35 456.175 122.915 614 317 614C511.085 614 669 456.175 669 262.199C669 249.769 668.356 237.19 667.018 224.859L667.166 224.81Z"
                    fill="#e9e9e9"
                  />
                </svg>
              </div>
              <Box width={open ? "calc(100vw - 256px)" : "calc(100vw - 94px)"}>
                {!updatingOrg && <Outlet />}
                {(isUploading || isLoading) && (
                  <Box
                    top={0}
                    left={0}
                    position={"fixed"}
                    width={"100vw"}
                    height={"100%"}
                    zIndex={9999}
                    sx={{
                      backgroundColor: "white",
                      opacity: 0.8,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      size={100}
                      sx={{
                        ml: open ? "256px" : "94px",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
