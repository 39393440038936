import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Stack, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { classes } from "../../utils/class";
import { PageTitle } from "../Typography";
import { CEmailInput } from "../CustomInput";

export const ContactResponsibleModal = ({
  open,
  title,
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const zones = useSelector((state) => state.zone.zoneList);
  const [data, setData] = useState([]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Stack sx={classes.popup}>
          <Stack>
            <PageTitle>{title}</PageTitle>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "-1%",
                paddingTop: "8px",
              }}
            >
              Select or invite the person responsible for managing this
              collection point
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <CEmailInput
            placeholder="Enter email to select or invite a new member"
            options={zones.map((zone, index) => {
              return {
                name: zone.name,
                value: zone._id,
              };
            })}
            value={data}
            onChange={(value) => {
              setData(value);
            }}
            onAddNewItem={(value) => {}}
          />
          <Typography
            sx={{
              fontFamily: "Manrope",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "15px",
              color: "#AFAFAF",
              marginTop: "-24px",
            }}
          >
            By default, Producer is the manager of this Collection Point
          </Typography>

          <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={classes.buttonSave}
              onClick={() => {
                handleSave(data);
              }}
            >
              Save & Invite
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
