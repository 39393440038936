import { useState } from "react";
import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { classes } from "../../utils/class";
import { PageTitle } from "../Typography";
import { CoordinatesMap } from "../CoordinatesMap";

export const CoordinatesModal = ({
  open,
  title,
  data = {},
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const [locationData, setLocationData] = useState(null);

  const onConfirmSave = () => {
    if (locationData) {
      handleSave(locationData);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Stack
          sx={{
            ...classes.popup,
            width: {
              lg: "1102px",
            },
          }}
        >
          <Stack>
            <PageTitle>{title}</PageTitle>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "-1%",
                paddingTop: "8px",
              }}
            >
              Select a point on the map to add the Collection Point location or
              enter the addresss
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <CoordinatesMap
            direction="row"
            data={locationData}
            onChange={(data) => {
              setLocationData(data);
            }}
          />

          <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={onConfirmSave}>
              Save and next
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
