import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stack } from "@mui/material";

import VisitsView from "./VisitsView";
import ScheduledVisitsView from "./ScheduledVisitsView";
import { PageTitle } from "../../../components/Typography";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import { classes } from "../../../utils/class";
import { DRIVER } from "../../../utils/constants";
import { setCurrentScheduled } from "../../../slices/visitsSlice";

const VisitsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.user.role);

  const tabChange = () => {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: 3,
        gap: "8px",

        paddingX: "60px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Visits</PageTitle>
        <Stack direction="row">
          <Button
            variant="outlined"
            sx={classes.buttonSave}
            onClick={() => {
              dispatch(setCurrentScheduled(null));
              navigate("/add-visit-log");
            }}
            disabled={role === DRIVER}
          >
            Add Visit
          </Button>
        </Stack>
      </Box>
      <Tabs onTabChangeHandler={tabChange}>
        <TabHeader>
          <TabHeaderItem>Planned Visits</TabHeaderItem>
          <TabHeaderItem>Past Visits</TabHeaderItem>
        </TabHeader>
        <></>
        <TabBody>
          <Tab>
            <ScheduledVisitsView />
          </Tab>
          <Tab>
            <VisitsView />
          </Tab>
        </TabBody>
      </Tabs>
    </Box>
  );
};

export default VisitsListPage;
