import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import { Percentage } from "../../../../components/Typography";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useNavigate } from "react-router";
// import {
//   ACTIVATE_ITEM,
//   DEACTIVATE_ITEM,
//   DELETE_ROW,
//   VIEW_ROW,
// } from "../../../../utils/type";
import { useDispatch, useSelector } from "react-redux";

import { StateSpan } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";
import { CardPrimary } from "../../../../components/Card";
import {
  activateProducerPickup,
  archiveProducerPickup,
  deactivateProducerPickup,
} from "../../../../actions/pickup";
import { setCurrentPickup } from "../../../../slices/pickupSlice";
// import RemovePickupDialog from "../Dialog/RemovePickupDialog";
import { classes } from "../../../../utils/class";
import { DRIVER, WAREHOUSE_MANAGER } from "../../../../utils/constants";

const PickupList = ({ id, props, extraInfo, onAdd = () => {} }) => {
  const navigate = useNavigate();
  const role = useSelector((state) => state.user.role);

  const dispatch = useDispatch();
  const rows = props.map((data, index) => {
    return {
      ...data,
      id: data._id,
    };
  });
  // const [currentPickupId, setCurrentPickupId] = useState(null);
  const columns = [
    {
      width: 200,
      flex: 3,
      field: "name",
      headerName: "Name",
      renderCell: (props) => {
        return (
          <>
            <Stack>
              <Stack>
                <Stack
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {props.row.name}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                <Stack>{props.row.official_id}</Stack>
                <Stack>{props.row.id}</Stack>
                <Stack>{props.row.producer}</Stack>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "raw_waste",
      headerName: "Raw waste",
      minWidth: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "lastPicking",
      width: 200,
      flex: 1,
      headerName: "Last picking",
      renderCell: (props) => {
        const daysAgo = props.row.last_picking_days;
        if (daysAgo === null || daysAgo === undefined) return <>-</>;
        else
          return (
            <Stack direction={"row"} gap={1}>
              {props.row.last_picking_days === 0 ? (
                "Today"
              ) : (
                <>{props.row.last_picking_days} days ago</>
              )}

              {props.row.description && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.description}
                  placement="top"
                >
                  <CommentOutlinedIcon
                    sx={{
                      color: "text.light",
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "qt",
      headerName: "Qt ",
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1}>
            {props.row.total_quantity}
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    background: "black",
                  },
                },
                arrow: {
                  sx: {
                    color: "black",
                  },
                },
              }}
              title="40% less than expected (167kg)"
              placement="top"
            >
              <Percentage value={props.row.ratio} />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "nextPicking",
      headerName: "Next collection",
      renderCell: (props) => {
        if (
          props.row.next_picking_days === null ||
          props.row.next_picking_days === undefined
        )
          return <>-</>;
        else return <>In {props.row.next_picking_days} days</>;
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "frequency",
      headerName: "Frequency",
      renderCell: (props) => {
        if (props.row.frequency === null) return <>-</>;
        else return <>{props.row.frequency} days</>;
      },
    },
    // {
    //   width: 200,
    //   field: "manager",
    //   headerName: "Manager",
    // },
    // {
    //   field: "zone",
    //   headerName: "Zone",
    // },
    // {
    //   field: "location_g",
    //   headerName: "Location",
    //   width: 200,
    // },
    // {
    //   field: "alert",
    //   headerName: "Alert",

    //   renderCell: (props) => {
    //     return (
    //       <Tooltip title="2 docs expiring" placement="top">
    //         <ReportProblemOutlinedIcon sx={{ color: "red" }} />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      flex: 1,
      renderCell: (props) => {
        let options = [];

        if (role === WAREHOUSE_MANAGER || role === DRIVER) {
          options = [];
        } else {
          if (props.value === "active") {
            options = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else if (props.value === "inactive") {
            options = [
              {
                label: "Active",
                value: "active",
              },
              {
                label: "Archive",
                value: "archive",
              },
            ];
          } else if (props.value === "archive") {
            options = [
              {
                label: "Inactive",
                value: "inactive",
              },
              {
                label: "Archive",
                value: "archive",
              },
            ];
          }
        }

        return (
          <>
            <StateSpan
              status={props.value}
              options={options}
              onStateChangeHandler={(status) => {
                if (status === "active") {
                  dispatch(
                    activateProducerPickup(id, {
                      pickup_point: props.row._id,
                    })
                  );
                } else if (status === "inactive") {
                  dispatch(
                    deactivateProducerPickup(id, {
                      pickup_point: props.row._id,
                    })
                  );
                } else if (status === "archive") {
                  dispatch(
                    archiveProducerPickup(id, {
                      pickup_point: props.row._id,
                    })
                  );
                }
              }}
            />
          </>
        );
      },
    },
    // {
    //   minWinWidth: 100,
    //   field: "action",
    //   headerName: "",
    //   headerClassName: "custom-header",
    //   renderCell: (props) => {
    //     let options = [];
    //     if (role === BACKOFFICE || role === OWNER) {
    //       if (props?.row?.status === "inactive") {
    //         options = [
    //           {
    //             label: "Activate collection point",
    //             value: "active",
    //           },
    //           // {
    //           //   label: "Archive collection point",
    //           //   value: "archive",
    //           // },
    //           // {
    //           //   label: "Delete collection point",
    //           //   value: "remove",
    //           // },
    //         ];
    //       } else if (props?.row?.status === "active") {
    //         options = [
    //           {
    //             label: "Inactivate collection point",
    //             value: "inactive",
    //           },
    //           // {
    //           //   label: "Delete collection point",
    //           //   value: "remove",
    //           // },
    //         ];
    //       }
    //     }

    //     if (role === WAREHOUSE_MANAGER || role === DRIVER) {
    //       options = [];
    //     }

    //     if (role === SALES_MANAGER) {
    //       if (props?.row?.status === "inactive") {
    //         options = [
    //           {
    //             label: "Activate collection point",
    //             value: "active",
    //           },
    //           // {
    //           //   label: "Delete collection point",
    //           //   value: "remove",
    //           // },
    //         ];
    //       } else if (props?.row?.status === "active") {
    //         options = [
    //           {
    //             label: "Inactivate collection point",
    //             value: "inactive",
    //           },
    //           // {
    //           //   label: "Delete collection point",
    //           //   value: "remove",
    //           // },
    //         ];
    //       }
    //     }

    //     if (role === SALES) {
    //       if (props?.row?.status === "inactive") {
    //         options = [
    //           {
    //             label: "Activate collection point",
    //             value: "active",
    //           },
    //         ];
    //       } else if (props?.row?.status === "active") {
    //         options = [
    //           {
    //             label: "Inactivate collection point",
    //             value: "inactive",
    //           },
    //         ];
    //       }
    //     }

    //     return (
    //       <>
    //         <EditDetail
    //           options={options}
    //           onStateChangeHandler={(state) => {
    //             if (state === VIEW_ROW) {
    //               dispatch(setCurrentPickup(props.row._id));
    //               navigate("/collection-detail");
    //             } else if (state === "remove") {
    //               setCurrentPickupId(props.row._id);
    //               setRemoveDialog(true);
    //               // dispatch(removePickup(id, { pickup_point: props.row._id }));
    //             }
    //             if (state === "active")
    //               dispatch(
    //                 activateProducerPickup(id, {
    //                   pickup_point: props.row._id,
    //                 })
    //               );
    //             if (state === "inactive")
    //               dispatch(
    //                 deactivateProducerPickup(id, {
    //                   pickup_point: props.row._id,
    //                 })
    //               );
    //           }}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];

  // const [removeDialog, setRemoveDialog] = useState(false);

  return (
    <>
      {props.length === 0 ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              marginTop: "200px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "400px",
            }}
          >
            <HomeOutlinedIcon sx={{ color: "text.tertiary", fontSize: 100 }} />
            <Typography
              sx={{
                paddingY: 1,
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
              }}
            >
              There are no collection points listed
            </Typography>

            <Button
              onClick={() => onAdd()}
              sx={classes.buttonPrimary}
              disabled={role === WAREHOUSE_MANAGER}
            >
              Add collection point
            </Button>
          </Box>
        </Box>
      ) : (
        <Stack gap={2}>
          <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
            <CardPrimary
              type={1}
              title={"Balance"}
              content={extraInfo?.balance || 0}
            />
            <CardPrimary
              type={2}
              title={"Nº Collection Points"}
              content={extraInfo?.count_of_pickup_points || 0}
            />
            <CardPrimary
              type={3}
              title={"Next collection"}
              content={`In ${extraInfo?.next_picking || 0} days`}
            />
          </Stack>
          <Stack>
            <DataTable
              fit="100%"
              rows={rows}
              columns={columns}
              // initialState={{
              //   pinnedColumns: { right: ["action"] },
              // }}
              onRowClick={(e) => {
                dispatch(setCurrentPickup(e.row._id));
                navigate("/collection-detail");
              }}
              sx={{
                "& .MuiDataGrid-pinnedColumnHeaders": {
                  backgroundColor: "green !important",
                  opacity: 0,
                },
              }}
            />
          </Stack>
          {/* <RemovePickupDialog
            open={removeDialog}
            onClose={() => {
              setRemoveDialog(false);
            }}
            onContinue={() => {
              dispatch(
                archiveProducerPickup(id, { pickup_point: currentPickupId })
              );
              setRemoveDialog(false);
            }}
          /> */}
        </Stack>
      )}
    </>
  );
};
export default PickupList;
