import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

import { CTextInput } from "../../../components/CustomInput";
import { classes } from "../../../utils/class";
import { chargeCredits } from "../../../actions/organizations";

const schema = yup.object().shape({
  amount: yup.string().required("This field is required"),
});

const RechargeBalancePage = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(0);
  const [total, setTotal] = useState(0);
  const [isCharging, setIsCharging] = useState(false);

  return (
    <Formik
      initialValues={{ amount: "" }}
      validationSchema={schema}
      onSubmit={async (values) => {
        console.log(values.amount);
        setIsCharging(true);
        try {
          const url = await chargeCredits(values?.amount);
          setIsCharging(false);
          if (url) {
            window.location.href = url;
          }
        } catch (e) {
          setIsCharging(false);
        }
      }}
    >
      {({ errors, values, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack px={4.5} pt={4}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize={32} fontWeight={700} color="#282828">
                  Recharge Balance
                </Typography>
                <Stack
                  gap={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    sx={classes.buttonCancel}
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={isCharging}
                    sx={classes.buttonPrimary}
                    type="submit"
                  >
                    {!isCharging && "Continue"}
                  </LoadingButton>
                </Stack>
              </Stack>
              <Stack direction="column" alignItems="center">
                <Stack pt={11} width={420}>
                  <Typography fontSize={24} fontWeight={700}>
                    Recharge amount
                  </Typography>
                  <Form
                    style={{
                      marginTop: 32,
                    }}
                  >
                    <Field
                      name="amount"
                      title="Select or enter amount"
                      component={CTextInput}
                      placeholder="Enter amount"
                      endIcon={
                        <Typography fontSize={14} color="#7783A1">
                          Credits
                        </Typography>
                      }
                      type="number"
                      value={values.amount}
                      handleChange={(e) => {
                        setFieldValue("amount", e.target.value);
                        setCredits(e.target.value);
                        setTotal(Number(e.target.value));
                      }}
                    />
                    <ErrorMessage
                      name="amount"
                      render={(msg) => (
                        <div
                          style={{
                            ...classes.bottomError,
                            color: "rgb(214, 76, 86)",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    />
                    <Stack
                      pt={4}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => {
                          setCredits(100);
                          setTotal(100);
                          setFieldValue("amount", 100);
                        }}
                        sx={
                          credits === 100 || credits === "100"
                            ? classes.buttonYellow
                            : classes.buttonCancel
                        }
                      >
                        100 Credits
                      </Button>
                      <Button
                        onClick={() => {
                          setCredits(500);
                          setTotal(500);
                          setFieldValue("amount", 500);
                        }}
                        sx={
                          credits === 500 || credits === "500"
                            ? classes.buttonYellow
                            : classes.buttonCancel
                        }
                      >
                        500 Credits
                      </Button>
                      <Button
                        onClick={() => {
                          setCredits(1000);
                          setTotal(1000);
                          setFieldValue("amount", 1000);
                        }}
                        sx={
                          credits === 1000 || credits === "1000"
                            ? classes.buttonYellow
                            : classes.buttonCancel
                        }
                      >
                        1,000 Credits
                      </Button>
                    </Stack>
                    <Stack
                      mt={4}
                      p={3}
                      borderRadius="6px"
                      border="none"
                      bgcolor="#464A53"
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography fontSize={16} fontWeight={700} color="#FFF">
                          Credits to Purchases:
                        </Typography>
                        <Typography fontSize={16} fontWeight={700} color="#FFF">
                          {Number(credits) || 0}
                        </Typography>
                      </Stack>
                      <Stack
                        pt={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography fontSize={16} fontWeight={700} color="#FFF">
                          Total Cost:
                        </Typography>
                        <Typography fontSize={16} fontWeight={700} color="#FFF">
                          {total?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Form>
                </Stack>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default RechargeBalancePage;
