import { Divider, Stack, Box, IconButton } from "@mui/material";
import { CircledLocation } from "./Location";
import { CustomDropDownIcon } from "../../../../components/icons/menu_icons/icons";
import { useEffect, useState } from "react";
import { IOSSwitch } from "../../../../components/CustomInput";
import { DAYS_COLORS } from "../../../../utils/constants";

const WasteProgressBar = ({ progress = 50, urgency = 0 }) => {
  const backgroundColor = DAYS_COLORS[urgency];

  return (
    <>
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="2"
          height="8"
          rx="1"
          fill={progress > 0 ? backgroundColor : "#F6F5F2"}
        />
        <rect
          x="0.5"
          y="0.5"
          width="1"
          height="7"
          rx="0.5"
          stroke="black"
          strokeOpacity="0.03"
        />
        <rect
          x="3"
          width="2"
          height="8"
          rx="1"
          fill={progress > 20 ? backgroundColor : "#F6F5F2"}
        />
        <rect
          x="3.5"
          y="0.5"
          width="1"
          height="7"
          rx="0.5"
          stroke="black"
          strokeOpacity="0.03"
        />
        <rect
          x="6"
          width="2"
          height="8"
          rx="1"
          fill={progress > 40 ? backgroundColor : "#F6F5F2"}
        />
        <rect
          x="6.5"
          y="0.5"
          width="1"
          height="7"
          rx="0.5"
          stroke="black"
          strokeOpacity="0.03"
        />
        <rect
          x="9"
          width="2"
          height="8"
          rx="1"
          fill={progress > 60 ? backgroundColor : "#F6F5F2"}
        />
        <rect
          x="9.5"
          y="0.5"
          width="1"
          height="7"
          rx="0.5"
          stroke="black"
          strokeOpacity="0.03"
        />
        <rect
          x="12"
          width="2"
          height="8"
          rx="1"
          fill={progress > 80 ? backgroundColor : "#F6F5F2"}
        />
        <rect
          x="12.5"
          y="0.5"
          width="1"
          height="7"
          rx="0.5"
          stroke="black"
          strokeOpacity="0.03"
        />
      </svg>
    </>
  );
};

const CollectionPointWaste = ({
  wasteName = "Used Cooking oil",
  percent = 20,
  days = 3,
  disabled = true,
  urgency = 0,
  onEnable = () => {},
  onDisable = () => {},
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <>
      <Stack
        sx={{
          fontSize: "12px",
          fontWeight: 600,
          fontFamily: "Manrope",
        }}
        color={disabled ? "#464A5380" : "#464A53"}
        flexDirection={"row"}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled === false) {
            setIsDisabled(!isDisabled);
            if (isDisabled) onEnable();
            else onDisable();
          }
        }}
      >
        <Box flex={1}>{wasteName}</Box>
        <Stack
          flex={1}
          flexDirection={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <WasteProgressBar progress={percent} urgency={urgency} />
          {percent !== null && <Box>{percent}%</Box>}
          {days !== null && <Box>{days}d</Box>}
          <Box>
            <IOSSwitch checked={!isDisabled} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

const PickupListItem = ({
  id = null,
  title = "King Lee's",
  location = "Location",
  urgency = 0,
  onSelect = () => {},
  selected = false,
  assigned = false,
  number = null,
  wastes = [],
  comfortableWastes = [],
  noVehicleSelected = false,
  onEnable = () => {},
  onDisable = () => {},
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const getUrgency = (day) => {
    if (day === null) {
      return 0;
    } else {
      if (day <= 0) {
        return 6;
      } else if (day > 0 && day <= 1) {
        return 5;
      } else if (day > 1 && day <= 3) {
        return 4;
      } else if (day > 3 && day < 15) {
        return 3;
      } else if (day >= 15 && day < 30) {
        return 2;
      } else if (day >= 30) {
        return 1;
      }
    }
  };

  const getBarrelUrgency = (barrel) => {
    if (barrel === 0) {
      return 6;
    } else if (barrel > 0 && barrel < 20) {
      return 0;
    } else if (barrel >= 20 && barrel < 40) {
      return 1;
    } else if (barrel >= 40 && barrel < 60) {
      return 2;
    } else if (barrel >= 60 && barrel < 80) {
      return 3;
    } else if (barrel >= 80 && barrel < 100) {
      return 4;
    } else if (barrel === 100) {
      return 5;
    } else {
      return 6;
    }
  };

  let remainDays = null;
  for (let i = 0; i < wastes?.length; i++) {
    if (wastes[i]?.remain_days !== null) {
      remainDays = wastes[i]?.remain_days;
      break;
    }
  }

  wastes?.forEach((waste) => {
    if (waste?.remain_days !== null) {
      if (remainDays > waste?.remain_days) {
        remainDays = waste?.remain_days;
      }
    }
  });

  return (
    <>
      <Stack
        onClick={() => {
          onSelect();
        }}
        padding={1}
        gap={1}
        sx={{
          minWidth: "280px",
          cursor: "pointer",
          "&:hover": {
            background: selected ? "#F2F5DC" : "#E5E7E9",
          },
          backgroundColor: selected ? "#F2F5DC" : "unset",
        }}
      >
        <Stack
          flexDirection={"row"}
          gap={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <CircledLocation
            assigned={assigned}
            selected={selected}
            urgency={getUrgency(remainDays)}
          >
            {number}
          </CircledLocation>
          <Stack>
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: 800,
                fontFamily: "Manrope",
                color: "#464A53",
              }}
            >
              {title}
            </Box>
            <Box
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                fontFamily: "Manrope",
                color: "#36354680",
              }}
            >
              {location}
            </Box>
          </Stack>
        </Stack>
        <Stack
          borderRadius={2}
          sx={{
            backgroundColor: "#FFFFFF",
            paddingX: "8px",
          }}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "16px",
                fontFamily: "Manrope",
              }}
            >
              Wastes: {wastes.length} of {wastes.length}
            </Box>
            <Box>
              <IconButton
                onClick={(e) => {
                  setCollapsed(!collapsed);
                }}
                sx={{
                  rotate: collapsed ? "180deg" : "0deg",
                }}
              >
                <CustomDropDownIcon />
              </IconButton>
            </Box>
          </Stack>
          {collapsed && (
            <Stack gap={1}>
              {wastes.map((waste, index) => {
                return (
                  <CollectionPointWaste
                    onEnable={() => {
                      onEnable(id, waste);
                    }}
                    onDisable={() => {
                      onDisable(id, waste);
                    }}
                    key={index}
                    days={
                      waste.remain_days === null
                        ? null
                        : Number(waste.remain_days).toFixed(0)
                    }
                    wasteName={waste.name}
                    percent={Number(waste.exist_ratio).toFixed(0)}
                    urgency={getUrgency(Number(waste.remain_days))}
                    disabled={
                      !noVehicleSelected &&
                      comfortableWastes.indexOf(waste.name) === -1
                    }
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

export { PickupListItem };
